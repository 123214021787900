import tippy, { Instance } from "tippy.js";
import {
  formatDate,
  formatFloat,
  getCropInterval,
  getCropIntervalCrop,
  getCropIntervalStr,
  isMobile,
  parseDate,
  relativeTimeDiff,
} from "../util";
import EventBus from "@/lib/event-bus";
import { phoneNumber, phoneNumberLink } from "../constants";

import Vue from 'vue';
import Component from 'vue-class-component';
import { Parcel } from "../types/Parcel";
import { GrowthStage } from "../types/GrowthStage";
import { CropInterval } from "../types/CropInterval";
import { Season } from "../types/Season";
import { AppLanguage } from "../i18n";
import { apiMediaURL } from "../api";
import { RasterKind } from "../types/Raster";
import { Farm } from "../types/Farm";

@Component<UtilsMixin>({
  watch: {
    "$language.current": function () {
      this.$emit("updateHead");
    },
  },
})
export default class UtilsMixin extends Vue {

  rasterKind: RasterKind;
  __tippyInstances: Instance[];

  get currencyNumberFormat() {
    try {
      return new Intl.NumberFormat(this.$language.current, {
        style: "currency",
        currency: "RON",
        currencyDisplay: "symbol",
        maximumFractionDigits: 2,
      });
    } catch (err) {
      return {
        format: function (value) {
          return `${parseInt(value)} lei`;
        },
      };
    }
  }
  get routeBack() {
    const backTo = this.$route.query.backTo as string;
    if (backTo && backTo.startsWith("/")) {
      return backTo;
    }
    return "#";
  }
  get selectedCropName() {
    return (
      this.$store.getters.selectedCropId &&
      this.getCropName(this.$store.getters.selectedCropId)
    );
  }
  get selectedParcelCropId() {
    const selectedParcel = this.$store.getters.selectedParcel;
    return this.getParcelCropId(selectedParcel);
  }
  get selectedParcelCropName() {
    if (!this.$store.getters.selectedParcel) return;
    return this.getParcelCropName(this.$store.getters.selectedParcel);
  }
  get isMobile() {
    return this.$store.getters.isMobile;
  }
  /**
   * Reset the viewport meta tag to force browsers to reset the zoom
   * on mobile devices.
   */
  zoomOutMobile() {
    if (!isMobile()) return;
    this.$nextTick(() => {
      const viewport: HTMLMetaElement = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        const value = viewport.content;
        viewport.content = "";
        viewport.content = value;
      }
    });
  }
  parcelCountText(nr: number) {
    if (nr <= 0) {
      return this.$gettext("No parcels");
    }
    return this.$gettextInterpolate(
      this.$ngettext("%{ n } parcel", "%{ n } parcels", nr),
      { n: nr }
    );
  }
  initTooltips() {
    this.$nextTick(() => {
      if (!this.$el || !this.$el.querySelectorAll) return;
      if (this.__tippyInstances) {
        this.__tippyInstances.forEach((instance) => instance.destroy());
      }
      this.__tippyInstances = tippy(
        this.$el.querySelectorAll("[data-tippy-content]"),
        {
          allowHTML: true,
          interactive: false,
        }
      );
    });
  }
  getCrop(cropId: string) {
    return this.$store.getters.allCrops[cropId];
  }
  getCropName(cropId: string, lang?: string) {
    const crop = this.getCrop(cropId);
    return (
      crop &&
      (crop.name[lang || this.$language.current] || this.$gettext("Unspecified crop"))
    );
  }
  getParcelCrop(parcel: Parcel, date?: string | Date) {
    if (!parcel) return null;
    const cropId = this.getCropIntervalCrop(parcel.crop_intervals, date);
    if (!cropId) {
      return {
        id: "no-crop",
        name: this.$gettext("Unspecified crop"),
      };
    }

    const allCrops = this.$store.getters.allCrops;
    return {
      id: cropId,
      name: allCrops[cropId]?.name[this.$language.current] as string,
    };
  }
  getParcelCropId(parcel: Parcel, date?: string | Date) {
    return this.getCropIntervalCrop(parcel?.crop_intervals, date);
  }
  getParcelCropName(parcel: Parcel, date?: string | Date): string {
    const crop = this.getParcelCrop(parcel, date);
    return crop && crop.name;
  }
  getParcelName(parcel: Parcel) {
    return [parcel.locality, [parcel.physical_block, parcel.parcel_no].join('-')]
      .map((part) => part.trim())
      .filter((part) => !!part)
      .join(" ");
  }
  getParcelLink(parcel: Parcel) {
    if (!parcel) return "";
    const raster = parcel.rasters[0];
    const date =
      parcel.sensing_date ||
      (raster && raster.sensing_date) ||
      formatDate(this.$store.getters.selectedDate);
    return {
      name: "map-observation-details",
      params: {
        farmId: parcel.farm || this.$store.getters.currentFarmId,
        seasonId: parcel.season || this.$store.getters.currentSeasonId,
        parcelId: parcel.id,
        date,
      },
      query: {
        rasterKind: (raster && raster.kind) || this.rasterKind,
      },
    };
  }
  getGrowthStagesForCrop(cropId: string) {
    if (!cropId) return;
    return this.$store.dispatch("getCropGrowthStages", cropId);
  }
  getGrowthStageName(growthStage: GrowthStage) {
    if (!growthStage) return "";
    return growthStage.stage[this.$language.current] || growthStage.stage.en;
  }
  showPaywall() {
    EventBus.$emit(EventBus.events.showPaywall);
  }
  openSearchSidebar() {
    this.$store.commit("setShowSearchSidebar", true);
  }
  closeSearchSidebar() {
    this.$store.commit("setShowSearchSidebar", false);
    this.$store.commit("setRastersSelectable", false);
    this.$store.commit("clearAllSelectedParcels");
  }
  changeCurrentParcelCrop() {
    EventBus.$emit(EventBus.events.showChangeParcelCrop);
  }
  getCropInterval(crop_intervals: CropInterval[], date?: string | Date, currentSeason?: Season) {
    if (!date) {
      date = this.$store.getters.selectedDate;
    }
    if (!currentSeason) {
      currentSeason = this.$store.getters.currentSeason;
    }
    return getCropInterval(crop_intervals, date, currentSeason);
  }
  getCropIntervalCrop(crop_intervals: CropInterval[], date?: string | Date, currentSeason?: Season) {
    if (!date) {
      date = this.$store.getters.selectedDate;
    }
    if (!currentSeason) {
      currentSeason = this.$store.getters.currentSeason;
    }
    return getCropIntervalCrop(crop_intervals, date, currentSeason);
  }
  getCropIntervalStr(interval: CropInterval, season?: Season, language?: string) {
    if (!season) {
      season = this.$store.getters.currentSeason;
    }
    if (!language) {
      language = this.$language.current;
    }
    return getCropIntervalStr(interval, season, language as AppLanguage);
  }
  getOGORPhoneNumber() {
    return phoneNumber;
  }
  getOGORPhoneNumberLink() {
    return phoneNumberLink;
  }
  formatFloat(num: number | string, digits: number) {
    return formatFloat(num, digits);
  }
  apiMediaURL(mediaURL: string) {
    return apiMediaURL(mediaURL);
  }
  /**
   *
   * @param date
   * @param relativeThreshold
   * @param justNowThreshold
   * @param absoluteOptions
   * @returns
   */
  relativeTimeStr(date: string | number | Date, relativeThreshold = 5 * 60000, justNowThreshold = 60000,
    absoluteOptions?: Intl.DateTimeFormatOptions) {

    const parsedDate = new Date(date);
    const diff = Date.now() - parsedDate.getTime();
    if (diff > relativeThreshold) {
      absoluteOptions = absoluteOptions || {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }
      return parsedDate.toLocaleDateString(this.$language.current, absoluteOptions);
    } else if(diff >= justNowThreshold) {
      const relativeDiff = relativeTimeDiff(parsedDate, new Date());
      let nString: string, n: number;
      if(relativeDiff.years) {
        nString = this.$ngettext("%{ n } year ago", "%{ n } years ago", relativeDiff.years);
        n = relativeDiff.years;
      } else if(relativeDiff.months) {
        nString = this.$ngettext("%{ n } month ago", "%{ n } months ago", relativeDiff.months);
        n = relativeDiff.months;
      } else if(relativeDiff.days) {
        nString = this.$ngettext("%{ n } day ago", "%{ n } days ago", relativeDiff.days);
        n = relativeDiff.days;
      } else if(relativeDiff.minutes) {
        nString = this.$ngettext("%{ n } minute ago", "%{ n } minutes ago", relativeDiff.minutes);
        n = relativeDiff.minutes;
      } else {
        nString = this.$ngettext("%{ n } second ago", "%{ n } seconds ago", relativeDiff.seconds);
        n = relativeDiff.seconds;
      }
      return this.$gettextInterpolate(nString, { n });
    } else {
      return this.$gettext("just now");
    }
  }

  getFarmName(farm: Farm) {
    if(farm?.id == this.$store.getters.demoFarmId) {
      return this.$gettext("DEMO farm");
    }
    return farm?.name;
  }

  isFeatureFlagActive(name: string) {
    return this.$store.getters.featureFlags[name]?.active;
  }

  parseDate(dateStr: string) {
    return parseDate(dateStr);
  }

  getParcelsInterval(parcels: Parcel[], date = this.$store.getters.selectedDate, season = this.$store.getters.currentSeason) {
    let startDate: Date, endDate: Date;
    parcels.forEach(parcel => {
      const interval = this.getCropInterval(parcel.crop_intervals, date, season);
      if(!interval) return;
      const intervalStartDate = parseDate(interval.computed_start_date);
      const intervalEndDate = parseDate(interval.computed_end_date);
      if(!startDate || intervalStartDate < startDate) {
        startDate = intervalStartDate;
      }
      if(!endDate || intervalEndDate > endDate) {
        endDate = intervalEndDate;
      }
    });
    return {
      startDate: startDate || season.start_date,
      endDate: endDate || season.end_date,
    }
  }
};
