var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"farm-menu-item",attrs:{"title":_vm.getFarmName(_vm.farm)}},[(_vm.farm.parcelAccessOnly)?_c('div',{staticClass:"request-full-access"},[_c('div',{staticClass:"material-icons brand-blue-text"},[_vm._v("info")]),_c('div',[_c('div',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"paragraph emphasis"},[_vm._v(" The map is only available when you have access to the entire farm. At the moment you only have access to some of the parcels. ")]),_c('div',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"muted click-me request-action",on:{"click":_vm.requestFarmAccess}},[_vm._v("Request access for the entire farm")])])]):_vm._e(),_c('router-link',{staticClass:"sidebar-menu-item hide-md",class:{
      disabled: !_vm.farm.hasSeasons || _vm.farm.parcelAccessOnly,
      active: _vm.matchedComponent == 'CropOverview',
    },attrs:{"to":{
      name: 'crop-overview-date-selected',
      params: Object.assign({}, _vm.defaultRouteParams),
    },"title":_vm.getFarmName(_vm.farm),"active-class":"selected"},nativeOn:{"click":function($event){return _vm.$emit('closeSidebar')}}},[_c('span',{staticClass:"material-icons"},[_vm._v("assignment")]),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Crops")])]),_c('router-link',{staticClass:"sidebar-menu-item",class:{
      disabled: !_vm.farm.hasSeasons || _vm.farm.parcelAccessOnly || _vm.parcelCount == 0,
      active: _vm.matchedComponent == 'MapView',
    },attrs:{"to":{
      name: 'map-date-selected',
      params: Object.assign({}, _vm.defaultRouteParams),
      query: { rasterKind: _vm.rasterKind },
    },"title":_vm.getFarmName(_vm.farm),"active-class":"selected"},nativeOn:{"click":function($event){return _vm.$emit('closeSidebar')}}},[_c('svg-icon',{attrs:{"src":require('@/assets/icons/map.svg')}}),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Map")])],1),_c('router-link',{staticClass:"sidebar-menu-item hide-md",class:{
      disabled: !_vm.farm.hasSeasons || _vm.farm.parcelAccessOnly,
      active: _vm.matchedComponent == 'GridView',
    },attrs:{"to":{
      name: 'grid-date-selected',
      params: Object.assign({}, _vm.defaultRouteParams),
      query: { rasterKind: _vm.rasterKind },
    },"title":_vm.getFarmName(_vm.farm),"active-class":"selected"},nativeOn:{"click":function($event){return _vm.$emit('closeSidebar')}}},[_c('svg-icon',{attrs:{"src":require('@/assets/icons/grid.svg')}}),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Grid")])],1),_c('div',{staticClass:"sidebar-menu-item",class:{ disabled: _vm.parcelCount == 0 },attrs:{"title":_vm.getFarmName(_vm.farm)},on:{"click":_vm.openSearchSidebar}},[_c('span',{staticClass:"material-icons"},[_vm._v("search")]),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Search Parcels")]),_vm._v("   "),(_vm.parcelCount > 0)?_c('span',[_vm._v("("+_vm._s(_vm.parcelCount)+")")]):_vm._e()]),(_vm.farm.hasFullAcess)?_c('router-link',{staticClass:"sidebar-menu-item",attrs:{"to":{
      name: 'manage-farm',
      params: { farmId: _vm.farm.id },
    },"title":_vm.getFarmName(_vm.farm),"active-class":"selected"},nativeOn:{"click":function($event){return _vm.$emit('closeSidebar')}}},[_c('svg-icon',{attrs:{"src":require('@/assets/icons/crop-rotation.svg')}}),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Crop rotation")])],1):_vm._e(),_c('div',{staticClass:"soil-analysis-wrapper",class:{ warning: (_vm.userLicenseIsTrial || (_vm.userLicenceIsPreTrial && _vm.hasParcelsForCurrentSeason)) && !_vm.isDemoFarm }},[(_vm.farm.hasFullAcess && (!_vm.userLicenceIsPreTrial || (_vm.userLicenceIsPreTrial && _vm.hasParcelsForCurrentSeason)))?_c('router-link',{staticClass:"sidebar-menu-item",attrs:{"to":{ name: 'alcedo-order', params: { farmId: _vm.farm.id } }},nativeOn:{"click":function($event){return _vm.$emit('closeSidebar')}}},[_c('svg-icon',{attrs:{"src":require('@/assets/icons/soil-analysis.svg')}}),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Soil analysis")])],1):_vm._e(),((_vm.userLicenseIsTrial || (_vm.userLicenceIsPreTrial && _vm.hasParcelsForCurrentSeason)) && !_vm.isDemoFarm)?_c('div',{staticClass:"disclaimer"},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Soil analysis is available only for users with an active OGOR license.")]),_c('span',[_vm._v(" ")]),_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"action-text text-bolder",on:{"click":_vm.showPaywall}},[_vm._v("Activate license →")])]):_vm._e()],1),(!_vm.isMobile)?_c('router-link',{staticClass:"sidebar-menu-item",class:{
      disabled: !_vm.farm.hasSeasons || _vm.farm.parcelAccessOnly || _vm.parcelCount == 0,
      active: _vm.matchedComponent == 'SoilMapView',
    },attrs:{"to":{
      name: 'soil-map',
      params: Object.assign({}, _vm.defaultRouteParams),
      query: { rasterKind: _vm.rasterKind },
    },"active-class":"selected"},nativeOn:{"click":function($event){return _vm.$emit('closeSidebar')}}},[_c('span',{staticClass:"material-icons"},[_vm._v("terrain")]),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Soil map")]),_c('span',{staticClass:"beta-badge",staticStyle:{"margin-left":"1rem"}})]):_vm._e(),_c('router-link',{staticClass:"sidebar-menu-item",attrs:{"to":_vm.yieldRoute},nativeOn:{"click":function($event){return _vm.$emit('closeSidebar')}}},[_c('svg-icon',{attrs:{"src":require('@/assets/icons/yield.svg')}}),_c('span',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Yield forecast")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }