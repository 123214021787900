import { ga4ID } from "../constants";
import Vue from 'vue';
import Component from 'vue-class-component';

let previousProperties: Object = {};
let previousConfig: Object = {};

interface MeasureEngagementTime {
  engagementTime: number;
  idle: boolean;
  firstInteraction: number;
  idleTimeout: number;
  setIdle: () => void;
  pulse: () => void;
}

@Component
export default class AnalyticsMixin extends Vue {
  async sendGA4Event(eventName: string, eventProps: Object = {}) {
    // console.log({ eventName, eventProps });
    try {
      // eslint-disable-next-line no-undef
      gtag("event", eventName, {
        ...eventProps,
        send_to: ga4ID,
      });
    } catch (e) {
      //pass
    }
  }
  async setGA4Properties(properties: Object = {}, reset: boolean = false) {
    const propertiesObj = reset
      ? properties
      : { ...previousProperties, ...properties };
    previousProperties = propertiesObj;
    try {
      // eslint-disable-next-line no-undef
      gtag("set", propertiesObj);
    } catch (e) {
      //pass
    }
  }
  setGA4Config(config = {}, reset = false, trackingID = ga4ID) {
    const configObj = reset ? config : { ...previousConfig, ...config };
    previousConfig = configObj;
    try {
      // eslint-disable-next-line no-undef
      gtag("config", trackingID, configObj);
    } catch (e) {
      //pass
    }
  }
  sendFbPixelEvent(eventName: string, eventProps: Object) {
    try {
      // eslint-disable-next-line no-undef
      fbq("track", eventName, {
        ...eventProps,
      });
    } catch (e) {
      //pass
    }
  }
  startMeasureEngagementTime(featureName: string, sendLoadEvent = true) {
    if (sendLoadEvent) {
      const loadEventName = featureName + "_loaded";
      this.sendGA4Event(loadEventName);
    }

    const eventName = featureName + "_time";
    this["__" + eventName] = {};
    const p: MeasureEngagementTime = this["__" + eventName];
    p.engagementTime = 0;
    p.idle = false;
    p.firstInteraction = Date.now();
    p.setIdle = () => {
      p.idle = true;
      if (p.firstInteraction) {
        p.engagementTime += Date.now() - p.firstInteraction;
      }
      p.firstInteraction = null;
    };
    p.idleTimeout = window.setTimeout(p.setIdle, 2500);

    p.pulse = () => {
      if (p.idle) {
        p.firstInteraction = Date.now();
        p.idle = false;
      }
      window.clearTimeout(p.idleTimeout);
      p.idleTimeout = window.setTimeout(p.setIdle, 2500);
    };

    window.addEventListener("mousemove", p.pulse);
    window.addEventListener("touchmove", p.pulse);
    window.addEventListener("scroll", p.pulse);
    window.addEventListener("click", p.pulse);
    window.addEventListener("keyup", p.pulse);
    window.addEventListener("keydown", p.pulse);
    window.addEventListener("focus", p.pulse);
    window.addEventListener("blur", p.setIdle);
  }
  stopMeasureEngagementTime(featureName: string) {
    const eventName = featureName + "_time";
    const p: MeasureEngagementTime = this["__" + eventName];
    if (!p) return;
    if (p.idleTimeout) {
      window.clearTimeout(p.idleTimeout);
      p.setIdle();
    }
    window.removeEventListener("mousemove", p.pulse);
    window.removeEventListener("touchmove", p.pulse);
    window.removeEventListener("scroll", p.pulse);
    window.removeEventListener("click", p.pulse);
    window.removeEventListener("keyup", p.pulse);
    window.removeEventListener("keydown", p.pulse);
    window.removeEventListener("focus", p.pulse);
    window.removeEventListener("blur", p.setIdle);
    const engagement_time_msec = p.engagementTime;
    delete this["__" + eventName];
    this.sendGA4Event(eventName, { engagement_time_msec });
  }
};
