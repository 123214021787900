import Vue from 'vue';
import Component from 'vue-class-component';

import EventBus from "../event-bus";
import { Notification } from "../types/misc/Notification";

@Component
export default class NotificationMixin extends Vue {
  notify(options: Notification) {
    let defaultIcon: string;
    switch (options.type) {
      case "error":
        defaultIcon = "error";
        break;
      case "warning":
        defaultIcon = "warning";
        break;
      case "info":
        defaultIcon = "info";
        break;
      case "success":
        defaultIcon = "done";
        break;
      default:
        defaultIcon = null;
    }
    EventBus.$emit("emitNotification", {
      dismissable: true,
      icon: defaultIcon,
      ...options,
    });
  }
};
