import { MapDrawStep } from '@/store/modules/mapDraw';
import Vue from 'vue';
import Component from 'vue-class-component';

interface MapDrawRouteProps {
  farmId?: string;
  seasonId?: string;
  tab?: MapDrawStep;
}

@Component
export default class RoutesMixin extends Vue {

  get routes() {
    const getters = this.$store.getters;
    return {
      mapDrawRoute: ({ farmId, seasonId, tab = 'parcel' }: MapDrawRouteProps) => {
        return {
          name: "map-draw",
          params: {
            farmId: farmId || getters.currentFarmId,
            seasonId: seasonId || getters.currentSeasonId,
          },
          query: { tab },
        };
      }
    }
  }
  
}