export enum SupportedCountries {
  RO = "RO",
  // MD = "MD",
  FR = "FR",
}

export const supportedCountyNames = {
  [SupportedCountries.RO]: {
    en: "Romania",
    ro: "România",
    fr: "Roumanie",
  },
  // [SupportedCountries.MD]: {
  //   en: "Moldova",
  //   ro: "Republica Moldova",
  //   fr: "Moldavie",
  // },
  [SupportedCountries.FR]: {
    en: "France",
    ro: "Franța",
    fr: "France",
  },
};