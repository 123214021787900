import { unaccent } from "@/lib/util";
import { shouldShowParcel } from "@/lib/parcelFilters";
import { ActionMap, ActionTree, createMutations, GetterMap, GetterTree, Module } from "..";
import { Parcel } from "@/lib/types/Parcel";

export interface SidebarState {
  showAppSidebar: boolean;
  showSearchSidebar: boolean;
  parcelSearchText: string;
}

function initialState(): SidebarState {
  return {
    showAppSidebar: false,
    showSearchSidebar: false,
    parcelSearchText: "",
  };
}

const mutations = createMutations({
  clearSidebar(state: SidebarState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setShowAppSidebar(state, value: boolean) {
    state.showAppSidebar = value;
  },
  setShowSearchSidebar(state, value: boolean) {
    state.showSearchSidebar = value;
  },
  setParcelSearchText(state, value: string) {
    state.parcelSearchText = value;
  },
});

export type SidebarMutations = typeof mutations;

export interface SidebarGetters extends GetterMap {
  showAppSidebar: boolean;
  showSearchSidebar: boolean;
  parcelSearchText: string;
  unaccentSearchText: string;
  sidebarParcels: Parcel[];
  areParcelsFiltered: boolean;
  filteredParcels: Parcel[];
  filteredParcelSize: number;
}

const getters: GetterTree<SidebarState, SidebarGetters> = {
  showAppSidebar(state) {
    return state.showAppSidebar;
  },
  showSearchSidebar(state) {
    return state.showSearchSidebar;
  },
  parcelSearchText(state) {
    return state.parcelSearchText;
  },
  unaccentSearchText(state) {
    return unaccent(state.parcelSearchText);
  },
  sidebarParcels(state, getters, rootState, rootGetters) {
    return rootGetters.parcelsForCurrentSeason;
  },
  areParcelsFiltered(state, getters, rootState, rootGetters) {
    return (
      !!getters.parcelSearchText ||
      !!rootGetters.selectedCropId ||
      rootGetters.selectedLocalityIds.length > 0
    );
  },
  filteredParcels(state, getters, rootState, rootGetters) {
    return (
      getters.sidebarParcels
        .filter((parcel) => shouldShowParcel(rootGetters, parcel))
        .sort((a, b) => b.hectares - a.hectares)
    );
  },
  filteredParcelSize(state, getters, rootState, rootGetters) {
    return getters.filteredParcels.reduce(
      (result, parcel) => result + parcel.hectares,
      0
    );
  },
}

export interface SidebarActions extends ActionMap {
}

const actions: ActionTree<SidebarState, SidebarGetters, SidebarActions> = {

}

export default {
  strict: true,
  state: initialState(),
  getters,
  mutations,
  actions,
} as Module<SidebarState, SidebarGetters, SidebarActions>;
