import { parseDate, seasonForDate } from "../../lib/util";
import { apiCall } from "../../lib/api";
import { demoDefaultSensingDate, _apiLimit } from "@/lib/constants";
import { BESeason, Season } from "@/lib/types/Season";
import { ActionMap, ActionTree, createMutations, GetterMap, GetterTree, Module } from "..";
import { Paginated } from "@/lib/types/Paginated";

export interface SeasonsState {
  seasons: Record<Season["id"], Season>;
  currentSeasonId: Season["id"];
}

function initialState(): SeasonsState {
  return {
    seasons: {},
    currentSeasonId: null,
  };
}

const mutations = createMutations({
  clearSeasons(state: SeasonsState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setSeasons(state, results: Season[]) {
    const seasons = {};
    results.forEach((value) => {
      seasons[value.id] = value;
    });
    state.seasons = seasons;
  },
  /**
   * Set the current season ID, needs to be kept in sync with the
   * dates module.
   */
  setCurrentSeasonId(state, seasonId: Season["id"]) {
    state.currentSeasonId = seasonId;
  },
});

export type SeasonsMutations = typeof mutations;

export interface SeasonsGetters extends GetterMap {
  seasons: SeasonsState["seasons"];
  sortedSeasons: Season[];
  seasonsByEndYear: SeasonsState["seasons"];
  totalSeasons: number;
  currentSeasonId: Season["id"];
  currentSeason: Season;
  previousSeason: Season;
  previousSeasonId: Season["id"];
  defaultDemoSeason: Season;
  defaultDemoSeasonId: Season["id"];
}

const getters: GetterTree<SeasonsState, SeasonsGetters> = {
  seasons(state) {
    return state.seasons;
  },
  sortedSeasons(state) {
    return Object.values(state.seasons).sort(
      (a, b) => b.start_date.getTime() - a.start_date.getTime()
    );
  },
  seasonsByEndYear(state) {
    const result = {};
    Object.values(state.seasons).forEach((season) => {
      result[season.end_date.getFullYear()] = season;
    });
    return result;
  },
  totalSeasons(state, getters) {
    return Object.keys(getters.seasons).length;
  },
  currentSeasonId(state) {
    return state.currentSeasonId;
  },
  currentSeason(state) {
    if (state.currentSeasonId) {
      return state.seasons[state.currentSeasonId];
    }
  },
  previousSeason(state, getters) {
    if (!getters.currentSeason) return;
    const previousYear = getters.currentSeason.end_date.getFullYear() - 1;
    return getters.seasonsByEndYear[previousYear];
  },
  previousSeasonId(state, getters) {
    return getters.previousSeason && getters.previousSeason.id;
  },
  defaultDemoSeason(state) {
    return seasonForDate(state.seasons, demoDefaultSensingDate);
  },
  defaultDemoSeasonId(state, getters) {
    return getters.defaultDemoSeason && getters.defaultDemoSeason.id;
  },
}

export interface SeasonsActions extends ActionMap {
  /**
   * Get available seasons for the currently selected farm from the API,
   * and store the results.
   *
   *  - parses the start and end date
  */
  getSeasons: () => Promise<void>;
   /**
   * Get the details for a single season.
   */
  getSeasonDetails: (seasonId: Season["id"]) => Promise<void>;
  getOrCreateSeason: (endYear: number | string) => Promise<Season>;
  /**
   * Remove a season
   */
  removeSeason: (seasonId: Season["id"]) => Promise<void>;
}

const actions: ActionTree<SeasonsState, SeasonsGetters, SeasonsActions> = {
  async getSeasons(context) {
    if (!context.rootGetters.currentFarmId) return;
    const response = await apiCall<Paginated<BESeason>>(
      "GET",
      `/farm/${context.rootGetters.currentFarmId}/season/`,
      {
        limit: _apiLimit,
      }
    );
    const seasons = response.results.map(processSeason);
    context.commit("setSeasons", seasons);
  },
  async getSeasonDetails(context, seasonId) {
    if (!context.rootGetters.currentFarmId) return;
    const response = await apiCall<BESeason>(
      "GET",
      `/farm/${context.rootGetters.currentFarmId}/season/${seasonId}/`
    );
    const season = processSeason(response);
    context.commit("setSeasons", [season]);
  },
  async getOrCreateSeason(context, endYear) {
    if (context.getters.seasonsByEndYear[endYear]) {
      return context.getters.seasonsByEndYear[endYear];
    }
    const response = await apiCall<BESeason>("POST", `/farm/${context.rootGetters.currentFarmId}/season/`, {}, { 
      end_year: endYear 
    });
    // Add the new season to the store
    const season = processSeason(response);
    context.commit("setSeasons", [
      ...Object.values(context.getters.seasons),
      season,
    ]);
    return context.getters.seasonsByEndYear[endYear];
  },
  async removeSeason(context, seasonId) {
    const seasons = context.getters.seasons;
    delete seasons[seasonId];
    context.commit("setSeasons", [ ...Object.values(seasons) ]);
  }
}

function processSeason(_value: BESeason) {
  let value: Season = _value as any as Season;
  if (value.start_date) {
    value.start_date = parseDate(_value.start_date);
  }
  if (value.end_date) {
    value.end_date = parseDate(_value.end_date);
  }

  value.same_year = !!(
    value.end_date &&
    value.start_date &&
    value.end_date.getFullYear() === value.start_date.getFullYear()
  );

  if (value.same_year || !value.end_date) {
    value.interval = "" + value.start_date.getFullYear();
  } else {
    value.interval =
      value.start_date.getFullYear() + "–" + value.end_date.getFullYear();
  }
  return value;
}

export default {
  strict: true,
  state: initialState(),
  getters,
  mutations,
  actions,
} as Module<SeasonsState, SeasonsGetters, SeasonsActions>;
