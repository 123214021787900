import { CropName, YieldData, YieldDataType, YieldUat } from "@/lib/types/Yield";
import { ActionMap, ActionTree, createMutations, GetterMap, GetterTree } from "..";


export interface YieldState {
  yieldDataType: YieldDataType;
  selectedCrop: CropName;
  counties: Record<string, YieldData>;
  selectedCountyCode: string;
  selectedUatIndex: number | null;
}

function initialState(): YieldState {
  return {
    yieldDataType: "yield",
    selectedCrop: "maize",
    counties: {},
    selectedCountyCode: null,
    selectedUatIndex: null,
  };
}

const mutations = createMutations({
  clearYield(state: YieldState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setYieldCounty(state, { countyCode, data }: { countyCode: string, data: YieldData }) {
    state.counties = { ...state.counties, [countyCode]: data };
  },
  setYieldSelectedCounty(state, countyCode: string) {
    state.selectedCountyCode = countyCode;
  },
  setYieldSelectedUat(state, index: number | null) {
    state.selectedUatIndex = index;
  },
  setYieldDataType(state, dataType: YieldDataType) {
    state.yieldDataType = dataType;
  },
  setYieldSelectedCrop(state, crop: CropName) {
    state.selectedCrop = crop;
  }
});

export type YieldMutations = typeof mutations;

export interface YieldGetters extends GetterMap {
  selectedYieldCountyCode: YieldState['selectedCountyCode'];
  selectedYieldCounty: YieldData;
  selectedYieldUatIndex: YieldState['selectedUatIndex'];
  selectedYieldUat: YieldUat | null;
  yieldDataType: YieldDataType;
  yieldSelectedCrop: YieldState['selectedCrop'];
  yieldPredictionForYear: YieldData['predictionForYear'];
  yieldLastUpdated: Date;
}

const getters: GetterTree<YieldState, YieldGetters> = {
  selectedYieldCountyCode(state) {
    return state.selectedCountyCode;
  },
  selectedYieldCounty(state) {
    return (
      state.selectedCountyCode !== null &&
      state.counties[state.selectedCountyCode]
    );
  },
  selectedYieldUatIndex(state) {
    return state.selectedUatIndex;
  },
  selectedYieldUat(state, getters) {
    return (
      state.selectedUatIndex !== null &&
      getters.selectedYieldCounty?.uats[state.selectedUatIndex]
    );
  },
  yieldDataType(state) {
    return state.yieldDataType;
  },
  yieldSelectedCrop(state) {
    return state.selectedCrop;
  },
  yieldPredictionForYear(state, getters) {
    return getters.selectedYieldCounty?.predictionForYear;
  },
  yieldLastUpdated(state, getters) {
    return new Date(getters.selectedYieldCounty?.lastUpdated);
  },
}

export interface YieldActions extends ActionMap {
  getAndSetCounty: (countyCode: string, redownload: boolean) => Promise<boolean>;
}

const actions: ActionTree<YieldState, YieldGetters, YieldActions> = {
  async getAndSetCounty(context, countyCode, redownload = false) {
    if (redownload || !context.state.counties[countyCode]) {
      try {
        const fetchedFile = await fetch(`/yield/counties/${countyCode}.json`);
        const data: YieldData = await fetchedFile.json();
        data.uats.sort((a, b) => a.name.localeCompare(b.name));
        context.commit("setYieldCounty", { countyCode, data });
      } catch (err) {
        return false;
      }
    }
    context.commit("setYieldSelectedCounty", countyCode);
    return true;
  },
}

export default {
  strict: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
