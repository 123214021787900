/**
 * Cache up to maxSize item.
 *
 * @param maxSize
 * @constructor
 */
export class LRUCache<T> {
  maxSize: number;
  cache: { [key: string]: T } = {};
  keys: string[] = [];

  constructor(maxSize: number = 20) {
    this.maxSize = maxSize;
  }

  /**
   * Get the item from the cache, if available.
   */
  getItem = (key: string) => this.cache[key];

  /**
   * Add an item in the cache, if there already is an item
   * with the corresponding key, simply update it.
   *
   * @param key
   * @param item
   */
  addItem = (key: string, item: T) => {
    if (this.cache[key]) {
      // Already in cache, update the cache value, and change
      // the position of the element;
      this.cache[key] = item;
      this.keys.splice(this.keys.indexOf(key), 1);
      this.keys.push(key);
      return;
    }

    if (this.keys.length === this.maxSize) {
      // Make room first
      delete this.cache[this.keys.shift()];
    }

    this.keys.push(key);
    this.cache[key] = item;
  };

  /**
   * Get all the values currently in the cache, starting from the
   * least recently used value.
   */
  values = () => {
    return this.keys.map((key) => this.cache[key]);
  };
}
