import { ActionMap, ActionTree, createMutations, GetterMap, GetterTree, Module, RootGetters, RootState } from "..";
import { logError } from "../../lib/util";

export interface LocationState {
  currentPosition: GeolocationPosition | null,
  locationPermissionStatus: PermissionState | 'unknown' | null,
  _locationWatcherId: number | null,
  _locationSubscriptions: number[],
  _locationSubscriptionAI: number,
  _positionPromise: Promise<GeolocationPosition>,
}

function initialState(): LocationState {
  return {
    currentPosition: null,
    locationPermissionStatus: null,
    _locationWatcherId: null,
    _locationSubscriptions: [],
    _locationSubscriptionAI: 1,
    _positionPromise: null,
  };
}

const positionOptions = {
  enableHighAccuracy: true,
};

function _getCurrentPosition(): Promise<GeolocationPosition> {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, positionOptions)
  );
}

const mutations = createMutations({
  clearLocation(state: LocationState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setCurrentPosition(state, value) {
    state.currentPosition = value;
  },
  setLocationPermissionStatus(state, value) {
    state.locationPermissionStatus = value;
  },
  set_locationWatcherId(state, value) {
    state._locationWatcherId = value;
  },
  set_locationSubscriptions(state, value) {
    state._locationSubscriptions = value;
  },
  set_locationSubscriptionAI(state, value) {
    state._locationSubscriptionAI = value;
  },
  set_positionPromise(state, value) {
    state._positionPromise = value;
  },
});

export type LocationMutations = typeof mutations;

export interface LocationGetters extends GetterMap {
  currentPosition: LocationState["currentPosition"]
  locationPermissionStatus: LocationState["locationPermissionStatus"]
}

const getters: GetterTree<LocationState, LocationGetters> = {
  currentPosition(state) {
    return state.currentPosition;
  },
  locationPermissionStatus(state) {
    return state.locationPermissionStatus;
  },
}

export interface LocationActions extends ActionMap {
  checkLocationPermission: () => Promise<LocationState["locationPermissionStatus"]>;
  _watchForPositionChange: () => Promise<void>;
  subscribeLocationWatch: (options?: { forced?: boolean }) => Promise<number>;
  unsubscribeLocationWatch: (subscriptionId: number) => Promise<void>;
};

const actions: ActionTree<LocationState, LocationGetters, LocationActions> = {
  async checkLocationPermission(context) {
    let locationPermissionStatus;
    try {
      locationPermissionStatus = (
        await navigator.permissions.query({
          name: "geolocation",
        })
      ).state;
    } catch (err) {
      locationPermissionStatus = "unknown";
    }
    context.commit("setLocationPermissionStatus", locationPermissionStatus);
    return locationPermissionStatus;
  },
  async _watchForPositionChange(context) {
    if (context.state._locationWatcherId)
      navigator.geolocation.clearWatch(context.state._locationWatcherId);

    // prevent race conditions
    const positionPromise = _getCurrentPosition();
    context.commit("set_positionPromise", positionPromise);
    const position = await positionPromise;
    context.commit("set_positionPromise", null);

    context.commit("setCurrentPosition", position);
    context.commit("setLocationPermissionStatus", "granted");
    const _locationWatcherId = navigator.geolocation.watchPosition(
      (position) => {
        context.commit("setCurrentPosition", position);
        context.commit("setLocationPermissionStatus", "granted");
      },
      (e) => {
        logError(e);
        context.commit("setCurrentPosition", null);
        context.commit("setLocationPermissionStatus", "denied");
      },
      positionOptions
    );
    context.commit("set_locationWatcherId", _locationWatcherId);
  },
  async subscribeLocationWatch(context, options) {
    const forced = options?.forced;
    const id = context.state._locationSubscriptionAI;
    context.commit("set_locationSubscriptionAI", id + 1);
    if (!context.state._locationWatcherId) {
      const permission =
        context.state.locationPermissionStatus ||
        (await context.dispatch("checkLocationPermission"));
      if (
        permission == "granted" ||
        (["unknown", "prompt"].includes(permission) && forced)
      ) {
        try {
          const positionPromise = context.state._positionPromise;
          if (positionPromise) {
            await positionPromise;
          } else {
            await context.dispatch("_watchForPositionChange");
          }
        } catch (err) {
          return null;
        }
      } else {
        return null;
      }
    }
    const _locationSubscriptions = [...context.state._locationSubscriptions];
    _locationSubscriptions.push(id);
    context.commit("set_locationSubscriptions", _locationSubscriptions);
    return id;
  },
  async unsubscribeLocationWatch(context, subscriptionId) {
    if (subscriptionId == null) return;
    const _locationSubscriptions = [...context.state._locationSubscriptions];
    const index = _locationSubscriptions.findIndex(
      (id) => id == subscriptionId
    );
    if (index >= 0) {
      _locationSubscriptions.splice(index, 1);
    }
    context.commit("set_locationSubscriptions", _locationSubscriptions);
    if (
      _locationSubscriptions.length == 0 &&
      context.state._locationWatcherId != null
    ) {
      navigator.geolocation.clearWatch(context.state._locationWatcherId);
      context.commit("set_locationWatcherId", null);
      context.commit("setCurrentPosition", null);
    }
  },
};

export default {
  state: initialState(),
  mutations,
  getters,
  actions,
} as Module<LocationState, LocationGetters, LocationActions>;