import { FeatureFlag, StateFeatureFlags } from "@/lib/types/feature-flags";
import { maybeParseJSON } from "@/lib/util";
import { ActionMap, ActionTree, createMutations, GetterMap, GetterTree, Module } from "..";
import { envConfig } from "../../lib/constants";

interface LocalStorageObject {
  [name: string]: boolean;
}

const featureFlags: readonly FeatureFlag[] = [
  {
    name: "dummy_notifications",
    description: "Will show buttons acting as notification triggers.",
  },
  {
    name: "show_any_potential_map",
    description: "Will show a random potential map for every parcel",
  }
] as const;

function processFeatureFlags() {
  const stateObject: StateFeatureFlags = {};
  featureFlags.forEach((flag) => {
    stateObject[flag.name] = { description: flag.description, active: false };
  });
  return stateObject;
}

export interface FeatureFlagsState {
  featureFlags: StateFeatureFlags;
}

function initialState(): FeatureFlagsState {
  return {
    featureFlags: processFeatureFlags(),
  };
}

const mutations = createMutations({
  setFeatureFlag(state: FeatureFlagsState, { flag, active }: { flag: string, active: boolean }) {
    if (!state.featureFlags[flag]) return;
    state.featureFlags[flag].active = active;
    const stored: LocalStorageObject = {};
    const flags = Object.keys(state.featureFlags);
    flags.forEach((flag) => {
      stored[flag] = state.featureFlags[flag].active;
    });
    window.localStorage.setItem("featureFlags", JSON.stringify(stored));
  },
});

export type FeatureFlagsMutations = typeof mutations;

export interface FeatureFlagsGetters extends GetterMap {
  featureFlags: StateFeatureFlags;
}


const getters: GetterTree<FeatureFlagsState, FeatureFlagsGetters> = {
  featureFlags(state) {
    return state.featureFlags;
  },
}

export interface FeatureFlagsActions extends ActionMap {
  restoreFeatureFlags: () => Promise<void>;
}

const actions: ActionTree<FeatureFlagsState, FeatureFlagsGetters, FeatureFlagsActions> = {
  async restoreFeatureFlags(context) {
    if (!envConfig.VUE_APP_FEATURE_FLAGS) {
      return;
    }
    let stored: LocalStorageObject = maybeParseJSON(window.localStorage.getItem("featureFlags"));
    if (stored) {
      Object.keys(stored).forEach((flag) => {
        const active = stored[flag];
        context.commit("setFeatureFlag", { flag, active });
      });
    }
  },
}

export default {
  strict: true,
  state: initialState(),
  getters,
  mutations,
  actions,
} as Module<FeatureFlagsState, FeatureFlagsGetters, FeatureFlagsActions>;
