
import Component from 'vue-class-component';
import NotificationMixin from './notifications';

@Component
export default class ClipboardMixin extends NotificationMixin {
  async copyStringToClipboard(text: string) {
    await this.clipboardAction(async () => {
      await navigator.clipboard.writeText(text);
      this.notify({
        type: "info",
        icon: "content_copy",
        text: this.$gettext("Link copied to clipboard"),
      });
    });
  }
  async writeBlobToClipboard(blob: Blob) {
    await this.clipboardAction(async () => {
      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob,
        }),
      ]);
      this.notify({
        type: "info",
        icon: "content_copy",
        text: this.$gettext("Image copied to clipboard"),
      });
    });
  }
  /**
   * Check if the clipboard permission is available and attempt
   * to perform the action in the callback.
   *
   * Catch any errors, and notify the users in case of failures.
   * This does hide the errors from us, but the Clipboard API
   * is still somewhat experimental.
   */
  async clipboardAction(callback: () => Promise<any>, name = "clipboard-write" as any) {
    let fail = false;
    try {
      const result = await navigator.permissions.query({
        name,
      });
      if (result.state !== "granted" && result.state !== "prompt") {
        fail = true;
      }
    } catch (e) {
      // Some browser don't understand permission query
    }

    if (!fail) {
      try {
        await callback();
      } catch (e) {
        fail = true;
      }
    }

    if (fail) {
      this.notify({
        type: "warning",
        text: this.$gettext("Could not copy to clipboard"),
      });
    }
  }
  /**
   * Share something with the Web Share API
   */
  async shareAPI({ title, text, url, files }: { title?: string, text?: string, url?: string, files?: File[], }) {
    if (files && (!navigator.canShare || !navigator.canShare({ files }))) {
      try {
        await this.writeBlobToClipboard(files[0]);
      } catch (err) {
        this.notify({
          type: "warning",
          text: this.$gettext("Could not share."),
        });
      }
      return Promise.resolve();
    }

    if (navigator.canShare) {
      if (files) {
        return navigator.share({ files });
      } else {
        let sharedContent: any = { title, text };
        if (url) {
          sharedContent = {...sharedContent, url };
        }
        return navigator.share(sharedContent);
      }
    } else {
      // Just copy link if the browser doesn't support the feature
      return this.copyStringToClipboard(url);
    }
  }
};
