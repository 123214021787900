import { render, staticRenderFns } from "./DemoFarmModal.vue?vue&type=template&id=6e7874ae&scoped=true&"
import script from "./DemoFarmModal.vue?vue&type=script&lang=ts&"
export * from "./DemoFarmModal.vue?vue&type=script&lang=ts&"
import style0 from "./DemoFarmModal.vue?vue&type=style&index=0&id=6e7874ae&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e7874ae",
  null
  
)

export default component.exports