import Vue from "vue";

import { apiCall } from "@/lib/api";
import { Locality } from "@/lib/types/Locality";
import { ActionMap, ActionTree, createMutations, GetterMap, GetterTree, Module } from "..";

export interface LocalitiesState {
  allLocalities: Record<string, Locality>;
  localitiesPerSeason: Record<string, Record<string, Locality>>;
}

function initialState(): LocalitiesState {
  return {
    allLocalities: {},
    localitiesPerSeason: {},
  };
}

const mutations = createMutations({
  clearLocalities(state: LocalitiesState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setLocalities(state, { localities, seasonId }: { localities: Locality[], seasonId: string }) {
    const localitiesForSeason = {};

    const allLocalitiesIds = Object.keys(state.allLocalities);
    const newLocatities = {};
    localities.forEach((locality) => {
      if (!allLocalitiesIds.includes(locality.id)) {
        locality.selected = false;
        newLocatities[locality.id] = locality;
        localitiesForSeason[locality.id] = locality;
      } else {
        localitiesForSeason[locality.id] = state.allLocalities[locality.id];
      }
    });

    state.allLocalities = {
      ...state.allLocalities,
      ...newLocatities,
    };

    state.localitiesPerSeason = {
      ...state.localitiesPerSeason,
      [seasonId]: localitiesForSeason
    };
  },
  setLocalitySelected(state, { localityId, status }: { localityId: Locality['id'], status: boolean }) {
    state.allLocalities[localityId].selected = status;
  },
  setLocalityListSelected(state, localityIdList: string[]) {
    Object.values(state.allLocalities).forEach(
      (locality) => (locality.selected = false)
    );
    localityIdList.forEach((localityId) => {
      if (state.allLocalities[localityId]) {
        state.allLocalities[localityId].selected = true;
      } else {
        // Ignore any weird values silently.
      }
    });
  },
});

export type LocalitiesMutations = typeof mutations;

export interface LocalitiesGetters extends GetterMap {
  localitiesForCurrentSeason: Record<string, Locality>;
  allLocalities: LocalitiesState['allLocalities'];
  localitiesPerSeason: LocalitiesState['localitiesPerSeason'];
  selectedLocalities: Locality[];
  selectedLocalityIds: string[];
  selectedLocalityIdsSet: Set<string>;
}

const getters: GetterTree<LocalitiesState, LocalitiesGetters> = {
  localitiesForCurrentSeason(state, getters, rootState, rootGetters) {
    if (!rootGetters.currentSeasonId) return {};
    return state.localitiesPerSeason[rootGetters.currentSeasonId] || {};
  },
  allLocalities(state) {
    return state.allLocalities;
  },
  localitiesPerSeason(state) {
    return state.localitiesPerSeason;
  },
  selectedLocalities(state, getters, rootState, rootGetters) {
    if (!rootGetters.currentSeason) return [];
    return Object.values(getters.localitiesForCurrentSeason).filter(
      (locality) => locality.selected
    );
  },
  selectedLocalityIds(state, getters) {
    return getters.selectedLocalities.map((locality) => locality.id);
  },
  selectedLocalityIdsSet(state, getters) {
    return new Set(getters.selectedLocalityIds);
  },
}

export interface LocalitiesActions extends ActionMap {
  /**
   * Get all the available localities from the API and store them.
  */
  getLocalities: (seasonId: string) => Promise<void>;
}

const actions: ActionTree<LocalitiesState, LocalitiesGetters, LocalitiesActions> = {
  async getLocalities(context, seasonId = null) {
    seasonId = seasonId || context.rootGetters.currentSeasonId;
    if (
      !context.rootGetters.currentFarmId ||
      !seasonId ||
      context.getters.localitiesPerSeason[seasonId]
    )
      return;

    const localities = await apiCall<Locality[]>(
      "GET",
      `/farm/${context.rootGetters.currentFarmId}/season/${seasonId}/localities/`
    );

    context.commit("setLocalities", { localities, seasonId });
  },
};

export default {
  strict: true,
  state: initialState(),
  getters,
  mutations,
  actions,
} as Module<LocalitiesState, LocalitiesGetters, LocalitiesActions> ;
