/**
 * Various constant values/settings used throughout the app.
 */
import { parseDate } from "@/lib/util";
import { RasterKind } from "./types/Raster";
import { MapLayer } from "./types/misc/MapLayer";

export const envConfig: { [name: string]: string } = Object.assign({}, process.env);

// XXX !!! WARNING !!!
// XXX THESE MUST BE MANUALLY KEPT IN SYNC WITH THE VALUES IN `ogor-backend/centroid`
// XXX DO NOT CHANGE THEM HERE, IF THEY HAVE NOT CHANGED THERE FIRST.

export const RASTER_TYPES = new Set(["NDVI", "NDVI_NEW", "NDWI", "MZ"]);
export const RASTER_TYPES_HUMAN: { [name in RasterKind]: string } = {
  NDVI: "NDVI",
  NDWI: "NDWI",
  MZ: "NDVI",
  NDVI_NEW: "NDVI",
  TCI: "TCI",
};

export const DEFAULT_RASTER_KIND = "NDVI_NEW";
export const RASTER_HISTOGRAMS = {
  MZ: {
    "-1.00, 0.05": "#422112",
    "0.05, 0.15": "#9f512a",
    "0.15, 0.25": "#cea815",
    "0.25, 0.30": "#fdfe00",
    "0.30, 0.35": "#e6ed01",
    "0.35, 0.40": "#d0df00",
    "0.40, 0.45": "#b9cf00",
    "0.45, 0.50": "#a2c000",
    "0.50, 0.55": "#8bb000",
    "0.55, 0.60": "#72a000",
    "0.60, 0.65": "#5b8e03",
    "0.65, 0.70": "#458000",
    "0.70, 0.75": "#2f6f00",
    "0.75, 0.80": "#176100",
    "0.80, 1.00": "#005200",
  },
  NDVI_NEW: {
    "-1.00, 0.05": "#422112",
    "0.05, 0.15": "#9f512a",
    "0.15, 0.25": "#cea815",
    "0.25, 0.30": "#fdfe00",
    "0.30, 0.35": "#ebf200",
    "0.35, 0.40": "#d9e600",
    "0.40, 0.45": "#c7d900",
    "0.45, 0.50": "#b5cd00",
    "0.50, 0.55": "#a3c100",
    "0.55, 0.60": "#91b400",
    "0.60, 0.65": "#7fa800",
    "0.65, 0.70": "#6d9b00",
    "0.70, 0.75": "#5b8f00",
    "0.75, 0.80": "#498300",
    "0.80, 0.85": "#377600",
    "0.85, 0.90": "#256a00",
    "0.90, 0.95": "#125e00",
    "0.95, 1.00": "#005200",
  },
  NDVI: {
    "-1.00, -0.20": "rgba(0, 0, 0, 255)",
    "-0.20, 0.00": "rgba(165 , 0, 38, 255)",
    "0.00, 0.10": "rgba(215 , 48 , 39, 255)",
    "0.10, 0.20": "rgba(244 , 109 , 67, 255)",
    "0.20, 0.30": "rgba(253 , 174 , 97, 255)",
    "0.30, 0.40": "rgba(254 , 224 , 139, 255)",
    "0.40, 0.50": "rgba(255 , 255 , 191, 255)",
    "0.50, 0.60": "rgba(217 , 239 , 139, 255)",
    "0.60, 0.70": "rgba(166 , 217 , 106, 255)",
    "0.70, 0.80": "rgba(102 , 189 , 99, 255)",
    "0.80, 0.90": "rgba(26 , 152 , 80, 255)",
    "0.90, 1.00": "rgba(0, 104 , 55, 255)",
  },
  NDWI: {
    "-1.00, 0.10": "rgba(251, 255, 147, 255)",
    "0.10, 0.20": "rgba(204, 231, 154, 255)",
    "0.20, 0.30": "rgba(163, 211, 160, 255)",
    "0.30, 0.40": "rgba(119, 189, 167, 255)",
    "0.40, 0.50": "rgba(84, 167, 177, 255)",
    "0.50, 0.60": "rgba(60, 143, 194, 255)",
    "0.60, 0.70": "rgba(37, 120, 211, 255)",
    "0.70, 0.80": "rgba(25,  83, 197, 255)",
    "0.80, 0.90": "rgba(15,  44, 178, 255)",
    "0.90, 1.00": "rgba(4,   1, 157, 255)",
  },
};

/**
 * The Key for the histogram, in order.
 */
export interface HistogramKey {
  interval: [number, number];
  display: string;
}

export const RASTER_HISTOGRAM_KEYS: { [kind in RasterKind]?: HistogramKey[] } = {
  MZ: [
    { interval: [0.8, 1], display: "0.80-1.00" },
    { interval: [0.75, 0.8], display: "0.75-0.80" },
    { interval: [0.7, 0.75], display: "0.70-0.75" },
    { interval: [0.65, 0.7], display: "0.65-0.70" },
    { interval: [0.6, 0.65], display: "0.60-0.65" },
    { interval: [0.55, 0.6], display: "0.55-0.60" },
    { interval: [0.5, 0.55], display: "0.50-0.55" },
    { interval: [0.45, 0.5], display: "0.45-0.50" },
    { interval: [0.4, 0.45], display: "0.40-0.45" },
    { interval: [0.35, 0.4], display: "0.35-0.40" },
    { interval: [0.3, 0.35], display: "0.30-0.35" },
    { interval: [0.25, 0.3], display: "0.25-0.30" },
    { interval: [0.15, 0.25], display: "0.15-0.25" },
    { interval: [0.05, 0.15], display: "0.05-0.15" },
    { interval: [-1, 0.05], display: "< 0.05" },
  ],
  NDVI_NEW: [
    { interval: [0.95, 1], display: "0.95-1.00" },
    { interval: [0.9, 0.95], display: "0.90-0.95" },
    { interval: [0.85, 0.9], display: "0.85-0.90" },
    { interval: [0.8, 0.85], display: "0.80-0.85" },
    { interval: [0.75, 0.8], display: "0.75-0.80" },
    { interval: [0.7, 0.75], display: "0.70-0.75" },
    { interval: [0.65, 0.7], display: "0.65-0.70" },
    { interval: [0.6, 0.65], display: "0.60-0.65" },
    { interval: [0.55, 0.6], display: "0.55-0.60" },
    { interval: [0.5, 0.55], display: "0.50-0.55" },
    { interval: [0.45, 0.5], display: "0.45-0.50" },
    { interval: [0.4, 0.45], display: "0.40-0.45" },
    { interval: [0.35, 0.4], display: "0.35-0.40" },
    { interval: [0.3, 0.35], display: "0.30-0.35" },
    { interval: [0.25, 0.3], display: "0.25-0.30" },
    { interval: [0.15, 0.25], display: "0.15-0.25" },
    { interval: [0.05, 0.15], display: "0.05-0.15" },
    { interval: [-1.0, 0.05], display: "< 0.05" },
  ],
  NDVI: [
    { interval: [0.9, 1.0], display: "0.9-1.0" },
    { interval: [0.8, 0.9], display: "0.8-0.9" },
    { interval: [0.7, 0.8], display: "0.7-0.8" },
    { interval: [0.6, 0.7], display: "0.6-0.7" },
    { interval: [0.5, 0.6], display: "0.5-0.6" },
    { interval: [0.4, 0.5], display: "0.4-0.5" },
    { interval: [0.3, 0.4], display: "0.3-0.4" },
    { interval: [0.2, 0.3], display: "0.2-0.3" },
    { interval: [0.1, 0.2], display: "0.1-0.2" },
    { interval: [0.0, 0.1], display: "0.0-0.1" },
    { interval: [-0.2, 0.0], display: "-0.2-0.0" },
    { interval: [-1.0, -0.2], display: "< -0.2" },
  ],
  NDWI: [
    { interval: [0.9, 1.0], display: "0.9-1.0" },
    { interval: [0.8, 0.9], display: "0.8-0.9" },
    { interval: [0.7, 0.8], display: "0.7-0.8" },
    { interval: [0.6, 0.7], display: "0.6-0.7" },
    { interval: [0.5, 0.6], display: "0.5-0.6" },
    { interval: [0.4, 0.5], display: "0.4-0.5" },
    { interval: [0.3, 0.4], display: "0.3-0.4" },
    { interval: [0.2, 0.3], display: "0.2-0.3" },
    { interval: [0.1, 0.2], display: "0.1-0.2" },
    { interval: [-1.0, 0.1], display: "< 0.1" },
  ],
};
// XXX ^^^ WARNING ^^^

export const minZoomLevel = envConfig.VUE_APP_MIN_ZOOM_LEVEL
  ? parseInt(envConfig.VUE_APP_MIN_ZOOM_LEVEL)
  : 14;
export const maxZoomLevel = envConfig.VUE_APP_MAX_ZOOM_LEVEL
  ? parseInt(envConfig.VUE_APP_MAX_ZOOM_LEVEL)
  : 17;

/**
 * Maximum number of license are in ha, after which we consider it "unlimited"
 * @type {number}
 */
export const unlimitedLicenseArea = 3000000;

/**
 * Earth radius in Km.
 * @type {number}
 */
export const earthRadius = 6371;

/**
 * Zoom level to use when showing a thumbnail of a raster.
 */
export const thumbnailZoomLevel = 14;
/**
 * Zoom level use by the backend to generate the placeholder.
 */
export const placeholderZoomLevel = 17;
/**
 * Scale transformation to use to transform the placeholder
 * in a thumbnail.
 * @type {number}
 */
export const placeholderScaleDown =
  1 / 2 ** (placeholderZoomLevel - thumbnailZoomLevel);

/**
 * Whether or not Sentry should be used for error reporting.
 */
export const useSentry =
  envConfig.NODE_ENV === "production" && !!envConfig.VUE_APP_SENTRY_DSN;

/**
 * Feature flags available
 */
export const featureFlags = {
  MZ: false, // Show Management zone rasters
};

/**
 * APIA scrape dry-run, defaults to false in production and to true in all other modes.
 */
export const scrapeDryRun = envConfig.VUE_APP_SCRAPE_DRY_RUN
  ? !!JSON.parse(envConfig.VUE_APP_SCRAPE_DRY_RUN.toLowerCase())
  : envConfig.NODE_ENV !== "production";

/**
 * APIA scrape timeout, how much to wait for the scraper to finish, after the task has
 * started.
 */
export const apiaScrapeTimeout =
  parseInt(envConfig.VUE_APP_SCRAPE_TIMEOUT || "60") * 1000;

/**
 * If secure protocols should be used, e.g. https and wss
 *
 * @type {boolean}
 */
const secure = window.location.protocol.toLowerCase() !== "http:";

/**
 * Default API base if nothing is specified in the env file(s).
 *
 * @type {string}
 */
const apiBaseDefault =
  envConfig.NODE_ENV === "development"
    ? `${window.location.protocol}//localhost:8000`
    : `${window.location.protocol}//${window.location.host}`;

/**
 * OGOR backend base (without the API endpoint)
 *
 * @type {string}
 */
export const apiBase = envConfig.VUE_APP_API_HOST
  ? `${window.location.protocol}//${envConfig.VUE_APP_API_HOST}`
  : apiBaseDefault;

const apiVersion = "v1";
/**
 * The Base for ALL API calls in the app.
 *
 * @type {string}
 */
export const apiURL = `${apiBase}/api/${apiVersion}`;

/**
 * API host
 *
 * @type {string}
 */
export const apiHost = new URL(apiURL).host;

/**
 * Base for all WS "endpoints"
 *
 * @type {string}
 */
export const wsBase = `${secure ? "wss" : "ws"}://${apiHost}/ws`;

export const GoogleMapsKey: string = envConfig.VUE_APP_GOOGLE_MAPS_KEY;
export const GoogleMapsVersion = "weekly";
export const ga4ID: string = envConfig.VUE_APP_GA4_ID;

export const PLACEHOLDER_COLOR = "#FDC776";
export const ERROR_COLOR = "#FF3333";

export const demoDefaultSensingDate = parseDate(
  envConfig.VUE_APP_DEMO_FARM_DEFAULT_DATE
);

export const skippedReasons = {
  OVER_MAX_COVER: "OVER_MAX_COVER",
};

/**
 * Minimum value for cloud coverage to display the icon.
 *
 * @type {number} between 0 and 1
 */
export const minCloudCoverage = 0.01;

export const additionalMapLayers: MapLayer[] = [
  {
    label: "OSM",
    name: "OpenStreetMap",
    group: "OpenStreetMap",
    choices: ["a", "b", "c"],
    url: ({ r, z, x, y }) =>
      `https://${r}.tile.openstreetmap.org/${z}/${x}/${y}.png`,
    tileSize: [256, 256],
    maxZoom: 19,
  },
  {
    label: "OSM no-labels",
    name: "OSM no-labels",
    group: "OpenStreetMap",
    choices: [],
    url: ({ z, x, y }) =>
      `https://tiles.wmflabs.org/osm-no-labels/${z}/${x}/${y}.png`,
    tileSize: [256, 256],
    maxZoom: 19,
  },
  {
    label: "Humanitarian",
    name: "Humanitarian",
    group: "OpenStreetMap",
    choices: ["a", "b"],
    url: ({ r, z, x, y }) =>
      `http://${r}.tile.openstreetmap.fr/hot/${z}/${x}/${y}.png`,
    tileSize: [256, 256],
    maxZoom: 20,
  },
  {
    label: "OpenTopoMap",
    name: "OpenTopoMap",
    group: "OpenStreetMap",
    choices: ["a", "b", "c"],
    url: ({ r, z, x, y }) =>
      `https://${r}.tile.opentopomap.org/${z}/${x}/${y}.png`,
    tileSize: [256, 256],
    maxZoom: 19,
  },
  {
    label: "Mapnik b/w",
    name: "Mapnik b/w",
    group: "OpenStreetMap",
    choices: [],
    url: ({ z, x, y }) =>
      `https://tiles.wmflabs.org/bw-mapnik/${z}/${x}/${y}.png`,
    tileSize: [256, 256],
    maxZoom: 19,
  },
];

export const phoneNumber = "+40 (754) 347 347";
export const phoneNumberLink = "tel:+40754347347";

export const _apiLimit = 10000;