import Vue from "vue";
import Vuex, { CommitOptions, DispatchOptions } from "vuex";

import map, { MapActions, MapGetters, MapMutations, MapState } from "./modules/map";
import user, { UserActions, UserGetters, UserMutations, UserState } from "./modules/user";
import crops, { CropsActions, CropsGetters, CropsMutations, CropsState } from "./modules/crops";
import dates, { DatesActions, DatesGetters, DatesMutations, DatesState } from "./modules/dates";
import farms, { FarmsActions, FarmsGetters, FarmsMutations, FarmsState } from "./modules/farms";
import sidebar, { SidebarActions, SidebarGetters, SidebarMutations, SidebarState } from "./modules/sidebar";
import parcels, { ParcelsActions, ParcelsGetters, ParcelsMutations, ParcelsState } from "./modules/parcels";
import meteo, { MeteoActions, MeteoGetters, MeteoMutations, MeteoState } from "./modules/meteo";
import rasters, { RastersActions, RastersGetters, RastersMutations, RastersState } from "./modules/rasters";
import seasons, { SeasonsActions, SeasonsGetters, SeasonsMutations, SeasonsState } from "./modules/seasons";
import internal, { InternalActions, InternalGetters, InternalMutations, InternalState } from "./modules/internal";
import localities, { LocalitiesActions, LocalitiesGetters, LocalitiesMutations, LocalitiesState } from "./modules/localities";
import featureFlags, { FeatureFlagsActions, FeatureFlagsGetters, FeatureFlagsMutations, FeatureFlagsState } from "./modules/featureFlags";
import notes, { NotesActions, NotesGetters, NotesMutations, NotesState } from "./modules/notes";
import location, { LocationActions, LocationGetters, LocationMutations, LocationState } from "./modules/location";
import yieldOgor, { YieldActions, YieldGetters, YieldMutations, YieldState } from "./modules/yield";
import mapDraw, { MapDrawActions, MapDrawGetters, MapDrawMutations, MapDrawState } from "./modules/mapDraw";
import { dateEqual, firstAvailableDate, seasonsForDate } from "@/lib/util";
import { ModuleTree, MutationTree, Store as _Store } from "vuex";
import { Season } from "@/lib/types/Season";
import agriculturalWorks, {
  AgriculturalWorksGetters,
  AgriculturalWorksMutations,
  AgriculturalWorksActions,
  AgriculturalWorkState,
} from "./modules/agriculturalWorks";

Vue.use(Vuex);

export interface RootState {
  crops: CropsState;
  dates: DatesState;
  farms: FarmsState;
  featureFlags: FeatureFlagsState;
  internal: InternalState;
  localities: LocalitiesState;
  location: LocationState;
  map: MapState;
  mapDraw: MapDrawState;
  notes: NotesState;
  parcels: ParcelsState;
  meteo: MeteoState;
  rasters: RastersState;
  seasons: SeasonsState;
  sidebar: SidebarState;
  user: UserState;
  yieldOgor: YieldState;
  agriculturalWorks: AgriculturalWorkState;
}

export type RootGetters =
  CropsGetters &
  DatesGetters &
  FarmsGetters &
  FeatureFlagsGetters &
  InternalGetters &
  LocalitiesGetters &
  LocationGetters &
  MapGetters &
  MapDrawGetters &
  NotesGetters &
  ParcelsGetters &
  MeteoGetters &
  RastersGetters &
  SeasonsGetters &
  SidebarGetters &
  UserGetters &
  YieldGetters &
  BaseGetters &
  AgriculturalWorksGetters;

export type RootMutations =
  CropsMutations &
  DatesMutations &
  FarmsMutations &
  FeatureFlagsMutations &
  InternalMutations &
  LocalitiesMutations &
  LocationMutations &
  MapMutations &
  MapDrawMutations &
  NotesMutations &
  ParcelsMutations &
  MeteoMutations &
  RastersMutations &
  SeasonsMutations &
  SidebarMutations &
  UserMutations &
  YieldMutations &
  BaseMutations &
  AgriculturalWorksMutations;

export type RootActions =
  CropsActions &
  DatesActions &
  FarmsActions &
  FeatureFlagsActions &
  InternalActions &
  LocalitiesActions &
  LocationActions &
  MapActions &
  MapDrawActions &
  NotesActions &
  ParcelsActions &
  RastersActions &
  MeteoActions &
  SeasonsActions &
  SidebarActions &
  UserActions &
  YieldActions &
  BaseActions &
  AgriculturalWorksActions;

export interface BaseState {

}

function initialState(): BaseState {
  return {
  };
}

const mutations = createMutations({
  clearBase(state: BaseState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
});

export type BaseMutations = typeof mutations;

export interface BaseGetters extends GetterMap {}

const getters: GetterTree<BaseState, BaseGetters> = {};

export interface BaseActions extends ActionMap {
  /**
   * Clear current state. Use this to avoid weirdness when switching between users.
   */
  clearState: () => Promise<void>;
  setDateFromSeason: (season: Season) => Promise<void>;
  setSelectedDateAndSeason: (payload: { date: Date, seasonId?: string }) => Promise<void>;
  getInitialData: () => Promise<void>;
}

const actions: ActionTree<BaseState, BaseGetters, BaseActions> = {
  async clearState(context) {
    context.commit("clearMap");
    context.commit("clearBase");
    context.commit("clearUser");
    context.commit("clearCrops");
    context.commit("clearDates");
    context.commit("clearFarms");
    context.commit("clearParcels");
    context.commit("clearRasters");
    context.commit("clearSeasons");
    context.commit("clearLocalities");
    context.commit("clearNotes");
    context.commit("clearAgriculturalWorks");
    // Should not be cleared, as it will confuse the progress bar
    // context.commit("clearInternal");
  },
  async setDateFromSeason(context, season) {
    await context.dispatch("setSelectedDateAndSeason", {
      date: firstAvailableDate(season, context.rootGetters.availableDates),
    });
  },
  async setSelectedDateAndSeason(context, { date, seasonId }) {
    const season =
      context.rootGetters.seasons[seasonId] ||
      seasonsForDate(context.rootGetters.seasons, date).filter((s) => s.parcels_count > 0)[0];
    const newSeasonId = season ? season.id : null;
    if (!dateEqual(context.rootGetters.selectedDate, date)) {
      context.commit("setSelectedDate", date);
    }
    if (context.rootGetters.currentSeasonId !== newSeasonId) {
      context.commit("setCurrentSeasonId", newSeasonId);
      if (newSeasonId) {
        await Promise.all([
          context.dispatch("getCrops"),
          context.dispatch("getLocalities"),
          // TODO: Loading parcels COULD be lazy (as not all views require all parcels)
          context.dispatch("getParcelsForCurrentSeason"),
          context.dispatch("getAgriculturalWorks")
        ]);
      }
    }
  },
  async getInitialData(context) {
    try {
      // Always load the initial data, such as the current user details
      await context.dispatch("getCurrentUser");
    } catch (e) {
      // Crash on any other error than 401 (Not authorized)
      if (e.statusCode !== 401) {
        throw e;
      }
    }

    if (context.rootGetters.user.email) {
      // If the user is logged in, load the farm list.
      await Promise.all([
        context.dispatch("getFarms"),
        context.dispatch("getAllCrops"),
      ]);
    } else {
      // Otherwise only load the demo farm
      await context.dispatch("getDemoFarmOnly");
    }
    context.dispatch("restoreFeatureFlags");
    context.dispatch("getNotesPreferences");
  },
}

const store = new Vuex.Store<RootState>({
  strict: true,
  state: initialState() as any,
  mutations,
  actions,
  getters,
  modules: {
    map,
    mapDraw,
    user,
    crops,
    dates,
    farms,
    parcels,
    rasters,
    meteo,
    seasons,
    internal,
    localities,
    sidebar,
    featureFlags,
    notes,
    yieldOgor,
    location,
    agriculturalWorks,
  },
});

store.watch((state) => state.parcels.selectedParcel, (parcel) => {
  store.dispatch('fetchMeteoHistory', parcel)
})
// TODO: Dispatch another event when crop interval changes

export default store

export interface Dispatch {
  <K extends keyof RootActions>(type: K, payload?: Parameters<RootActions[K]>[0], options?: DispatchOptions): ReturnType<RootActions[K]>;
}

export interface Commit<M extends MutationTree<any>> {
  <K extends keyof M>(type: K, payload?: Parameters<M[K]>[1], options?: CommitOptions): void;
}

export interface ActionContext<S, G, RS = RootState, RG = RootGetters> {
  dispatch: Dispatch;
  commit: Commit<RootMutations>;
  state: S;
  getters: G;
  rootState: RS;
  rootGetters: RG;
}

export type GetterMap<K = {}> = {
  [key in keyof K]: any
}

export type ActionMap<K = {}> = {
  [key in keyof K]: (payload?: any) => Promise<any>
}

export type ComputedGetters<T extends GetterTree<any, any>> = {
  readonly [key in keyof T]: ReturnType<T[key]>;
}

export type ActionHandler<S, G, RS = RootState, RG = RootGetters, P = any, R = any> = (this: Store<RS>, injectee: ActionContext<S, G, RS, RG>, payload?: P) => R;

export type Getter<S, G extends GetterMap, RS = RootState, RG = RootGetters, R = any> = (state: S, getters: G, rootState: RS, rootGetters: RG) => R;
export type Action<S, G, RS = RootState, RG = RootGetters, P = any, R = any> = ActionHandler<S, G, RS, RG, P, R>;
export type Mutation<S, P = any> = (state: S, payload?: P) => any;

export function createMutations<State, M extends MutationTree<State>>(mutations: M) {
  return mutations;
}

export type GetterTree<S, G extends GetterMap, RS = RootState, RG = RootGetters> = {
  [Name in keyof G]: Getter<S, G, RS, RG, G[Name]>;
}

export type ActionTree<S, G, A extends ActionMap, RS = RootState, RG = RootGetters> = {
  // @ts-ignore
  [Name in keyof A]: Action<S, G, RS, RG, Parameters<A[Name]>[0], ReturnType<A[Name]>>;
}

export interface Module<S, G extends GetterMap, A extends ActionMap, RS = RootState, RG = RootGetters> {
  namespaced?: boolean;
  state?: S | (() => S);
  getters?: GetterTree<S, G, RS, RG>;
  actions?: ActionTree<S, G, A, RS, RG>;
  mutations?: MutationTree<S>;
  modules?: ModuleTree<RS>;
}

export declare class Store<S = RootState> extends _Store<S> {
  readonly getters: RootGetters;
  dispatch: Dispatch;
  commit: Commit<RootMutations>;
}
