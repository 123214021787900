import { Note, NoteCreationAttributes } from "@/lib/types/Note";
import { Paginated } from "@/lib/types/Paginated";
import { Season } from "@/lib/types/Season";
import { ActionContext, ActionMap, ActionTree, createMutations, GetterMap, GetterTree, Module } from "..";
import { apiCall } from "../../lib/api";
import {
  copyObject,
  dateBetween,
  getCropInterval,
  getFeatureData,
  getToday,
  parseDate,
  setFeatureDataItem,
} from "../../lib/util";

export enum NoteTimeFilter {
  FOREVER = 0,
  LAST_TWO_WEEKS = 1,
  THIS_SEASON = 2,
};

export interface NoteFilters {
  read: boolean;
  unread: boolean;
  solved: boolean;
  time: NoteTimeFilter;
}

export interface NotesState {
  notes: Note[];
  notesForFarmId: string;
  showNotes: boolean;
  noteFilters: NoteFilters;
  showAddPhotos: boolean;
}

export interface VisibilitySettings {
  showNotes: boolean;
  noteFilters: NoteFilters;
}

function prepareNotePayload(note: Note | NoteCreationAttributes, context: Context) {
  const preparedNote = { ...note };
  const parcelObject = context.rootGetters.parcelIndex[note.parcel];
  const interval = getCropInterval(
    parcelObject?.crop_intervals,
    context.rootGetters.selectedDate,
    context.rootGetters.currentSeason
  );
  preparedNote.crop_interval = interval?.id;
  return preparedNote;
}

function endpointForNote(note: Note | NoteCreationAttributes, context: Context, nestedUrl: boolean = true) {
  const { farm, season, parcel, id } = note as Note;
  let endpoint = `/mapnotes/farm/${farm}`;
  if (nestedUrl) {
    if (parcel) {
      endpoint += `/season/${season}/parcel/${parcel}`;
    }
  }
  endpoint += "/mapnote/";
  if (id) {
    endpoint += `${id}/`;
  }
  return endpoint;
}

function initialState(): NotesState {
  return {
    notes: [],
    notesForFarmId: null,
    showNotes: true,
    noteFilters: {
      read: true,
      unread: true,
      solved: true,
      time: NoteTimeFilter.FOREVER,
    },
    showAddPhotos: false,
  };
}

const mutations = createMutations({
  clearNotes(state: NotesState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setNotes(state, notes: Note[]) {
    state.notes = notes;
  },
  setNotesForFarmId(state, farmId: string) {
    state.notesForFarmId = farmId;
  },
  setShowNotes(state, showNotes: boolean) {
    state.showNotes = showNotes;
    setFeatureDataItem("visibilitySettings", "showNotes", showNotes);
  },
  setNoteFilters(state, noteFilters: Partial<NoteFilters>) {
    state.noteFilters = { ...state.noteFilters, ...noteFilters };
    setFeatureDataItem("visibilitySettings", "noteFilters", state.noteFilters);
  },
  setShowAddPhotos(state, value: boolean) {
    state.showAddPhotos = value;
  }
});

export type NotesMutations = typeof mutations;

export interface NotesGetters extends GetterMap {
  notes: Note[];
  showNotes: boolean;
  showSolvedNotes: boolean;
  noteFilters: NoteFilters;
  filteredNotes: Note[];
  showAddPhotos: boolean;
}

const getters: GetterTree<NotesState, NotesGetters> = {
  notes(state) {
    return state.notes;
  },
  showNotes(state) {
    return state.showNotes;
  },
  showSolvedNotes(state) {
    return state.noteFilters.solved;
  },
  noteFilters(state) {
    return state.noteFilters;
  },
  filteredNotes(state, getters, rootState, rootGetters) {
    const filters = state.noteFilters;
    const timeFilterFunction = getTimeFilterFunction(filters.time, rootGetters.currentSeason);
    const statusFilterFunction = getStatusFilterFunction(filters);
    return state.notes.filter((note) => (statusFilterFunction(note) && timeFilterFunction(note)));
  },
  showAddPhotos(state) {
    return state.showAddPhotos;
  }
};

export interface NotesActions extends ActionMap {
  getNotesPreferences: () => Promise<void>
  getNotesForCurrentFarm: (reload?: boolean) => Promise<void>;
  addNote: (note: Note | NoteCreationAttributes) => Promise<Note>;
  patchNote: ({note, initialNote}: { note: Note | NoteCreationAttributes, initialNote?: Note}) => Promise<Note>;
  deleteNote: (note: Note) => Promise<void>;
}

type Context = ActionContext<NotesState, NotesGetters>;

const actions: ActionTree<NotesState, NotesGetters, NotesActions> = {
  async getNotesPreferences(context) {
    const data = getFeatureData<VisibilitySettings>("visibilitySettings");
    context.commit("setShowNotes", data.showNotes ?? true);
    context.commit("setNoteFilters", data.noteFilters ?? {
      read: true,
      unread: true,
      solved: true,
      time: NoteTimeFilter.FOREVER,
    });
  },
  async getNotesForCurrentFarm(context, reload = false) {
    if(!context.rootGetters.currentFarm) return;
    const currentFarm = context.rootGetters.currentFarm;
    const notesForFarmId = context.state.notesForFarmId;
    if (currentFarm.id != notesForFarmId || reload) {
      const notes = (
        await apiCall<Paginated<Note>>("GET", `/mapnotes/farm/${currentFarm.id}/mapnote/`, {
          limit: 10000,
        })
      ).results;
      context.commit("setNotes", notes);
      context.commit("setNotesForFarmId", currentFarm.id);
    }
  },
  async addNote(context, note) {
    const payload = prepareNotePayload(note, context);
    const endpoint = endpointForNote(note, context);
    const result = await apiCall<Note>("POST", endpoint, {}, payload);
    await context.dispatch("getNotesForCurrentFarm", true);
    return result;
  },
  async patchNote(context, { note, initialNote }) {
    const payload = prepareNotePayload(note, context);
    const endpoint = endpointForNote(initialNote || note, context, false);
    const preparedNote = copyObject(payload);
    preparedNote.photos = preparedNote.photos.map((photo) => {
      if (photo.id) return { id: photo.id };
      return photo;
    });
    const result = await apiCall<Note>("PATCH", endpoint, {}, preparedNote);
    await context.dispatch("getNotesForCurrentFarm", true);
    return result;
  },
  async deleteNote(context, note) {
    const endpoint = endpointForNote(note, context);
    await apiCall("DELETE", endpoint);
    await context.dispatch("getNotesForCurrentFarm", true);
  },
}

export default {
  strict: true,
  state: initialState(),
  getters,
  mutations,
  actions,
} as Module<NotesState, NotesGetters, NotesActions>;


function getTimeFilterFunction(filter: NoteTimeFilter, currentSeason: Season) {
  const getNoteDate = (note: Note) => parseDate(note.user_created_date);
  switch (filter) {
    case NoteTimeFilter.FOREVER:
      return () => true;
    case NoteTimeFilter.LAST_TWO_WEEKS:
      return (note: Note) => getNoteDate(note).getTime() >= getToday().getTime() - 14 * 24 * 60 * 60 * 1000;
    case NoteTimeFilter.THIS_SEASON:
      return (note: Note) => dateBetween(getNoteDate(note), currentSeason.start_date, currentSeason.end_date);
    default:
      throw "wrong condition";
  }
}

function getStatusFilterFunction(filters: NoteFilters) {
  if(filters.solved) return () => true;
  return (note: Note) => note.status != "solved";
}