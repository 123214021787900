import { apiCall } from "@/lib/api";
import { User } from "@/lib/types/User";
import { UserLicense } from "@/lib/types/UserLicense";
import { ActionMap, ActionTree, ComputedGetters, createMutations, GetterMap, GetterTree, Module, RootState } from "..";

export interface UserState {
  currentUser: User;
  linkEmail: string;
  attemptedLogin: {
    email: string | null;
    newUser: boolean | null;
  }
}

function initialState(): UserState {
  return {
    currentUser: {
      id: null,
      email: null,
      phone: null,
      name: null,
      last_name: null,
      first_name: null,
      is_staff: null,
      is_active: null,
      is_data_source: null,
      is_hijacked: null,
      verified_email: true,
      preferred_language: null,
      privacy: false,
      generaltos: false,
      marketing: false,
      license: null,
    } as User,
    // An email address provided as query argument from a link that
    // the user has clicked.
    linkEmail: "",
    attemptedLogin: {
      email: null,
      newUser: null,
    },
  };
}

const mutations = createMutations({
  clearUser(state: UserState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setLinkEmail(state, email) {
    state.linkEmail = email || "";
  },
  setAttemptedLogin(state, attemptedLogin: UserState['attemptedLogin']) {
    state.attemptedLogin = attemptedLogin;
  },
  setCurrentUser(state, currentUser: User) {
    Object.assign(state.currentUser, currentUser);
    state.currentUser.name = [state.currentUser.first_name, state.currentUser.last_name ]
      .filter(Boolean)
      .join(" ");
  },
});

export type UserMutations = typeof mutations;

export interface UserGetters extends GetterMap {
  user: User;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  linkEmail: string;
  attemptedLogin: UserState["attemptedLogin"];
  userIsConfirmed: boolean;
  userIsStaffOrHijacked: boolean;
  userLicense: UserLicense;
  userLicenseIsTrial: boolean;
  userLicenceIsPreTrial: boolean;
  userLicenseIsActive: boolean;
  userLatestLegalConsent: boolean;
}

const getters: GetterTree<UserState, UserGetters> = {
  user(state) {
    return state.currentUser;
  },
  userFirstName(state) {
    return state.currentUser.first_name;
  },
  userLastName(state) {
    return state.currentUser.last_name;
  },
  userEmail(state) {
    return state.currentUser.email;
  },
  linkEmail(state) {
    return state.linkEmail;
  },
  attemptedLogin(state) {
    return state.attemptedLogin;
  },
  userIsConfirmed(state) {
    return state.currentUser.verified_email;
  },
  userIsStaffOrHijacked(state) {
    return state.currentUser.is_staff || state.currentUser.is_hijacked;
  },
  userLicense(state) {
    return state.currentUser?.license;
  },
  userLicenseIsTrial(state, getters) {
    return getters.userLicense?.is_trial;
  },
  userLicenceIsPreTrial(state, getters) {
    return getters.userLicense?.is_pre_trial;
  },
  userLicenseIsActive(state, getters) {
    // If the backend has no license information, assume the license
    // is active. This is both for backwards compatibility and a
    // failsafe.
    return !getters.userLicense || getters.userLicense.is_active;
  },
  userLatestLegalConsent(state) {
    return state.currentUser.privacy && state.currentUser.generaltos;
  },
}

export interface UserActions extends ActionMap {
  getCurrentUser: () => Promise<void>
}

const actions: ActionTree<UserState, UserGetters, UserActions> = {
  async getCurrentUser(context) {
    const response = await apiCall<User>("GET", "/auth/user/");
    context.commit("setCurrentUser", response);
  }
}

export default {
  state: initialState(),
  mutations,
  actions,
  getters,
} as Module<UserState, UserGetters, UserActions>;
