import { apiCall, ignoreAPIError } from "@/lib/api";
import { featureFlags, _apiLimit } from "@/lib/constants";
import { SupportedCountries } from "@/lib/supported-countries";
import { BEFarm, Farm } from "@/lib/types/Farm";
import { Paginated } from "@/lib/types/Paginated";
import { Parcel } from "@/lib/types/Parcel";
import { Module, RootState, RootGetters, createMutations, GetterTree, GetterMap, ActionMap, ActionTree } from "..";
import { getFeatureData, setFeatureDataItem } from "../../lib/util";

export type FarmMap = Record<string, Farm>;

export interface FarmsState {
  demoFarmId: string,
  userFarms: FarmMap
  sharedFarms: FarmMap
  allFarms: FarmMap
  currentFarmId: string,
  totalFarms: number,
}

function initialState(): FarmsState {
  return {
    demoFarmId: null,
    userFarms: {},
    sharedFarms: {},
    allFarms: {},
    currentFarmId: null,
    totalFarms: -1,
  };
}

function setFarm(state: FarmsState, results: BEFarm[], farmType: "userFarms" | "sharedFarms") {
  const farms = {};
  results.forEach((value) => {
    farms[value.id] = value;
  });
  state[farmType] = farms;
  state.allFarms = {
    ...state.allFarms,
    ...farms,
  };
}

function processFarm(_farm: BEFarm) {
  let farm: Farm = _farm as any;
  farm.hectares = parseFloat(_farm.hectares) || 0.0;
  farm.parcel_count = _farm.parcel_count || 0;
  farm.hasNewParcels = false;
  return farm;
}

/**
 * Demo farm needs some finagling. Manually set some flag,
 * to ensure consistency across components.
 */
function processDemoFarm(_farm: BEFarm) {
  let farm = processFarm(_farm);
  farm.ownedFarm = false;
  farm.hasAccess = true;
  farm.hasFullAcess = false;
  farm.parcelAccessOnly = false;
  farm.hasSeasons = true;
  farm.shared_farm = "view_only";
  farm.sharedParcels = null;
  return farm;
}

function processSharedFarm(_farm: BEFarm) {
  let farm = processFarm(_farm);
  if (!_farm.shared_farm) {
    farm.shared_farm = "view_only";
  }
  farm.ownedFarm = false;
  farm.hasAccess = farm.shared_farm === "view_only";
  farm.hasFullAcess = farm.shared_farm === "full_access";
  farm.parcelAccessOnly = farm.shared_farm === "no_access";
  farm.hasSeasons = _farm.seasons?.length > 0;
  farm.sharedParcels = [];
  return farm;
}

function processOwnedFarm(_farm: BEFarm) {
  let farm = processFarm(_farm);
  farm.ownedFarm = true;
  farm.hasAccess = true;
  farm.hasFullAcess = true;
  farm.parcelAccessOnly = false;
  farm.hasSeasons = farm.seasons?.length > 0;
  farm.shared_farm = "full_access";
  farm.sharedParcels = null;
  return farm;
}

const mutations = createMutations({
  clearFarms(state: FarmsState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setHasNewParcels(state, { farmId, hasNewParcels }: { farmId: string, hasNewParcels: boolean }) {
    const farm = state.allFarms[farmId];
    if (!farm) return;

    farm.hasNewParcels = hasNewParcels;
  },
  /**
   * Set the available farms from an API response.
   */
  setUserFarms(state, results: BEFarm[]) {
    setFarm(state, results, "userFarms");
    state.totalFarms = results.length;
  },
  /**
   * Set the shared farms from an API response.
   */
  setSharedFarms(state, results: BEFarm[]) {
    setFarm(state, results, "sharedFarms");
  },
  setSharedParcels(state, { farmId, sharedParcels }: { farmId: string, sharedParcels: Parcel[] }) {
    if (!state.allFarms[farmId]) return;

    state.allFarms[farmId].sharedParcels = sharedParcels;
  },
  addFarmDetails(state, value) {
    value.parcel_count = parseInt(value.parcel_count) || 0;
    value.hectares = parseFloat(value.hectares) || 0.0;
    value.hasNewParcels = false;
    state.allFarms[value.id] = value;
  },
  /**
   * Set the currently currently selected farm ID.
   */
  setCurrentFarmId(state, farmId: string) {
    state.currentFarmId = farmId;
    try {
      // Only remember farms with parcels
      const isDemoFarm = state.demoFarmId == farmId;
      const farm = state.allFarms[farmId];
      if (farm.parcel_count > 0 && !isDemoFarm) {
        setFeatureDataItem("userPreferences", "defaultUserFarmId", farmId);
      }
    } catch (e) {
      // pass
    }
  },
  setDemoFarmId(state, farmId: string) {
    state.demoFarmId = farmId;
  },
});

export type FarmsMutations = typeof mutations;

export interface FarmsGetters extends GetterMap {
  allFarms: FarmMap;
  farms: FarmMap;
  farmsWithSeasons: Farm[];
  farmsWithParcels: Farm[];
  userFarms: Farm[];
  sharedFarms: Farm[];
  managedFarms: Farm[];
  farmIds: string[];
  totalFarms: number;
  currentFarmId: string;
  currentFarm: Farm;
  currentFarmCountry: SupportedCountries;
  currentFarmFlags: Record<string, boolean>;
  userOwnsFarm: boolean;
  userHasAccessToFarm: boolean;
  userHasFullAccessToFarm: boolean;
  demoFarm: Farm;
  demoFarmId: string;
  isDemoFarm: boolean;
  defaultUserFarm: Farm;
  hasOwnSeasons: boolean;
}

const getters: GetterTree<FarmsState, FarmsGetters> = {
  allFarms(state) {
    return state.allFarms;
  },
  farms(state) {
    return state.userFarms;
  },
  farmsWithSeasons(state) {
    return Object.values(state.userFarms).filter(
      (farm) => farm.seasons.length > 0
    );
  },
  farmsWithParcels(state) {
    return Object.values(state.userFarms)
      .concat(Object.values(state.sharedFarms))
      .filter((farm) => farm.parcel_count > 0);
  },
  userFarms(state) {
    return Object.values(state.userFarms);
  },
  sharedFarms(state) {
    return Object.values(state.sharedFarms);
  },
  managedFarms(state, getters) {
    return [...getters.userFarms, ...getters.sharedFarms.filter(farm => farm.hasFullAcess)];
  },
  farmIds(state) {
    return Object.keys(state.userFarms);
  },
  totalFarms(state) {
    return state.totalFarms;
  },
  currentFarmId(state) {
    return state.currentFarmId;
  },
  currentFarm(state) {
    if (state.currentFarmId) {
      return (
        state.userFarms[state.currentFarmId] ||
        state.sharedFarms[state.currentFarmId] ||
        state.allFarms[state.currentFarmId]
      );
    }
  },
  currentFarmCountry(state, getters) {
    return getters.currentFarm?.country;
  },
  currentFarmFlags(state, getters) {
    const flags = { ...featureFlags };
    if (getters.currentFarm && getters.currentFarm.feature_flags) {
      getters.currentFarm.feature_flags.forEach(
        (flag) => (flags[flag] = true)
      );
    }
    return flags;
  },
  userOwnsFarm(state) {
    return !!state.userFarms[state.currentFarmId];
  },
  userHasAccessToFarm(state, getters) {
    return (
      getters.currentFarm &&
      (getters.currentFarm.hasAccess || getters.currentFarm.hasFullAcess)
    );
  },
  userHasFullAccessToFarm(state, getters) {
    return getters.currentFarm && getters.currentFarm.hasFullAcess;
  },
  demoFarm(state) {
    return state.allFarms[state.demoFarmId];
  },
  demoFarmId(state) {
    return state.demoFarmId;
  },
  isDemoFarm(state) {
    return state.currentFarmId && state.currentFarmId === state.demoFarmId;
  },
  defaultUserFarm(state, getters) {
    const defaultFarmId = getFeatureData("userPreferences")["defaultUserFarmId"];
    const defaultFarm = getters.allFarms[defaultFarmId];
    return defaultFarm || getters.farmsWithParcels[0] || getters.demoFarm;
  },
  hasOwnSeasons(state, getters) {
    return getters.farmsWithSeasons.length > 0;
  },
};

export interface FarmsActions extends ActionMap {
  getFarms: () => Promise<void>;
  getSingleSharedFarm: (farmId: string) => Promise<void>;
  getDemoFarmOnly: () => Promise<void>;
  setCurrentFarm: (farmId: string) => Promise<void>;
  removeFarm: (farmId: string) => Promise<void>;
}

const actions: ActionTree<FarmsState, FarmsGetters, FarmsActions> = {
  /**
   * Get all the available farms from the API for the current user.
   */
  async getFarms(context) {
    const [demoFarm, userFarms, sharedFarms] = await Promise.all([
      apiCall<Paginated<BEFarm>>("GET", "/demo-farm/"),
      apiCall<Paginated<BEFarm>>("GET", "/farm/", { limit: _apiLimit }),
      ignoreAPIError<Paginated<BEFarm>>(
        apiCall("GET", "/shared-farms/", { limit: _apiLimit }),
        { count: 0, results: [] },
        // This API can return 403 if the user is not verified yet.
        [403]
      ),
    ]);

    // Compute access flags here to simplify logic across the app.
    if (demoFarm.results.length > 0) {
      context.commit("setDemoFarmId", demoFarm.results[0].id);
      processDemoFarm(demoFarm.results[0]);
    }
    userFarms.results.forEach(processOwnedFarm);
    sharedFarms.results.forEach(processSharedFarm);

    context.commit("setUserFarms", userFarms.results);
    context.commit("setSharedFarms", [
      ...demoFarm.results,
      ...sharedFarms.results,
    ]);
    await context.dispatch("getAllSharedParcels");
  },
  async getSingleSharedFarm(context, farmId: string) {
    const farm = await apiCall<BEFarm>("GET", `/farm/${farmId}/`);
    context.commit("setSharedFarms", [context.getters.demoFarm as any as BEFarm, farm]);
  },
  async getDemoFarmOnly(context) {
    if (context.getters.demoFarmId) return;
    const demoFarm = await apiCall<Paginated<BEFarm>>("GET", "/demo-farm/");
    if (demoFarm.results.length > 0) {
      context.commit("setDemoFarmId", demoFarm.results[0].id);
      processDemoFarm(demoFarm.results[0]);
      context.commit("setSharedFarms", demoFarm.results);
    }
  },
  /**
   * Set the current farm ID and dispatch action to load the data loaded to the new
   * farm.
   */
  async setCurrentFarm(context, farmId) {
    if (
      context.state.currentFarmId === farmId ||
      !context.state.allFarms[farmId]
    )
      return;
    context.commit("setCurrentFarmId", farmId);
    await Promise.all([
      context.dispatch("setCurrentParcel", null),
      context.dispatch("setSelectedDateAndSeason", { date: null }),
      context.dispatch("getAvailableDates"),
      context.dispatch("getSeasons"),
    ]);
  },

  /**
   * Remove farm
   */
  async removeFarm(context, farmId: string) {
    delete context.state.userFarms[farmId]
    delete context.state.allFarms[farmId]

    const nextFarmId = Object.keys(context.state.allFarms)[0]
    context.dispatch("setCurrentFarm", nextFarmId)
    // context.dispatch("getFarms")

    // mutations.setCurrentFarmId(state, nextFarmId)
  }
};

const farms: Module<FarmsState, FarmsGetters, any> = {
  state: initialState(),
  mutations,
  getters,
  actions,
}

export default farms;
