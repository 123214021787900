/**
 * Handle i18n initialization and language change.
 */

import Vue from "vue";
import GetTextPlugin from "vue-gettext";
import EventBus from "./event-bus";

export type AppLanguage = 'en' | 'ro' | 'fr';

const defaultLanguage = "en";
// If this list changes the prerender list in the `vue.config.js` file needs to be
// updated as well.
const translations = { en: {}, ro: {}, fr: {} };
const translationLoaders = {
  en: () => import("../assets/translations/en.json"),
  ro: () => import("../assets/translations/ro.json"),
  fr: () => import("../assets/translations/fr.json"),
};

/**
 * Initialize the translations engine.
 */
export function initI18n() {
  Vue.use(GetTextPlugin, {
    availableLanguages: {
      ro: "Română",
      fr: "Français",
      en: "English",
    },
    defaultLanguage,
    translations,
    silent: true,
  });
}

/**
 * Change the application current language. If the translations are not loaded
 * yet, they are first fetched from the server before loading.
 *
 * @param languageKey The two letter language code.
 * @param vm  The Vue instance
 * @param savePreference if set to true save the user pref in the local storage
 */
export async function setLanguage(languageKey: string, vm: Vue, savePreference = true) {
  if (
    !translations[languageKey] ||
    !Object.keys(translations[languageKey]).length
  ) {
    EventBus.$emit(EventBus.events.requestStarted);
    try {
      const languageResponse = await translationLoaders[languageKey]();
      translations[languageKey] = languageResponse[languageKey];
    } catch (e) {
      EventBus.$emit(EventBus.events.apiUnknownError);
    } finally {
      EventBus.$emit(EventBus.events.requestFinished);
    }
  }
  if (savePreference) {
    try {
      window.localStorage.setItem("language", languageKey);
    } catch (e) {
      // User doesn't seem to allow storage
    }
  }
  window.currentLanguage = languageKey;
  vm.$language.current = languageKey;
}
