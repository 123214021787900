export interface Notification {
  type?: NotificationType;
  text: string;
  icon?: string | { svg: string } | { material: string }
  unique?: boolean;
  action?: string;
  onAction?: Function;
  duration?: number;
  dismissable?: boolean;
  onDismiss?: Function;
}

export type NotificationType = "error" | "warning" | "info" | "success";

export function isIconSvg(icon: Notification["icon"]): icon is { svg: string } {
  if (typeof icon == "string") {
    return false;
  } else if (typeof icon == "object") {
    return "svg" in icon;
  }
}