import Vue from "vue";
import { LRUCache } from "@/lib/LRUCache";
import { apiCall } from "@/lib/api";
import { DEFAULT_RASTER_KIND, RASTER_TYPES_HUMAN } from "@/lib/constants";
import { Raster, RasterKind } from "@/lib/types/Raster";
import { ActionMap, ActionTree, createMutations, GetterMap, GetterTree, Module } from "..";
import { Paginated } from "@/lib/types/Paginated";
import { Parcel } from "@/lib/types/Parcel";

export interface RastersState {
  parcelRasterCache: LRUCache<Raster[]>;
  rastersSelectable: boolean;
  selectedRasters: Record<Parcel["id"], Parcel>;
  selectedRastersCount: number;
  rasterKind: RasterKind;
  rasterIndex: Record<Raster["id"], Raster>;
}

function initialState(): RastersState {
  return {
    parcelRasterCache: new LRUCache(5),
    rastersSelectable: false,
    selectedRasters: {},
    selectedRastersCount: 0,
    rasterKind: DEFAULT_RASTER_KIND,
    rasterIndex: {},
  };
}

const mutations = createMutations({
  clearRasters(state: RastersState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  addToParcelRasterCache(state, { id, rasters }: { id: string, rasters: Raster[] }) {
    state.parcelRasterCache.addItem(id, rasters);
  },
  clearSelected(state) {
    state.rastersSelectable = false;
    state.selectedRasters = {};
    state.selectedRastersCount = 0;
  },
  addSelectedRaster(state, parcel: Parcel) {
    Vue.set(state.selectedRasters, parcel.uniqueKey, parcel);
    state.selectedRastersCount = Object.keys(state.selectedRasters).length;
  },
  removeSelectedRaster<T extends { uniqueKey: string }>(state: RastersState, parcel: T) {
    Vue.delete(state.selectedRasters, parcel.uniqueKey);
    state.selectedRastersCount = Object.keys(state.selectedRasters).length;
  },
  setSelectedRasters(state, parcels: Parcel[]) {
    const newSelectedRasters = {};
    parcels.forEach((parcel) => {
      newSelectedRasters[parcel.id] = parcel;
    });
    state.selectedRasters = newSelectedRasters;
    state.selectedRastersCount = parcels.length;
  },
  setRastersSelectable(state, status: boolean) {
    state.rastersSelectable = status;
  },
  setRasterKind(state, rasterKind: RasterKind) {
    state.rasterKind = rasterKind;
  },
  setRasterIndex(state, rasterIndex: Record<string, Raster>) {
    state.rasterIndex = rasterIndex;
  },
});

export type RastersMutations = typeof mutations;

export interface RastersGetters extends GetterMap {
  rastersSelectable: RastersState["rastersSelectable"];
  selectedRasters: RastersState["selectedRasters"];
  parcelRasterCache: RastersState["parcelRasterCache"];
  selectedRastersCount: RastersState["selectedRastersCount"];
  selectedRastersArea: number;
  rasterKind: RastersState["rasterKind"];
  humanRasterKind: typeof RASTER_TYPES_HUMAN[keyof typeof RASTER_TYPES_HUMAN];
  rasterIndex: RastersState["rasterIndex"];
}

const getters: GetterTree<RastersState, RastersGetters> = {
  rastersSelectable(state) {
    return state.rastersSelectable;
  },
  selectedRasters(state) {
    return state.selectedRasters;
  },
  parcelRasterCache(state) {
    return state.parcelRasterCache;
  },
  selectedRastersCount(state) {
    return state.selectedRastersCount;
  },
  selectedRastersArea(state, getters, rootState, rootGetters) {
    return Object.values(state.selectedRasters).reduce(
      (total, parcelRaster) => total + parcelRaster.hectares,
      0
    );
  },
  rasterKind(state) {
    return state.rasterKind;
  },
  humanRasterKind(state) {
    return RASTER_TYPES_HUMAN[state.rasterKind];
  },
  rasterIndex(state) {
    return state.rasterIndex;
  },
}

export interface RastersActions extends ActionMap {
  getRastersForCurrentParcel: (options: { kind?: RasterKind, includeSkipped?: boolean, noCache?: boolean }) => Promise<Raster[]>;
  getRastersForParcel: (options: { farmId: string, seasonId: string, parcelId: string, kind?: RasterKind, includeSkipped?: boolean, noCache?: boolean }) => Promise<Raster[]>;
}

const actions: ActionTree<RastersState, RastersGetters, RastersActions> = {
  async getRastersForCurrentParcel(context, { kind, includeSkipped, noCache } = {} as any) {
    if (!context.rootGetters.currentFarmId || !context.rootGetters.currentSeasonId || !context.rootGetters.selectedParcelId)
      return [];

    return await context.dispatch("getRastersForParcel", {
      kind,
      includeSkipped,
      noCache,
      farmId: context.rootGetters.currentFarmId,
      seasonId: context.rootGetters.currentSeasonId,
      parcelId: context.rootGetters.selectedParcelId,
    });
  },
  async getRastersForParcel(context, { farmId, seasonId, parcelId, includeSkipped, kind, noCache }) {
    kind = kind || context.getters.rasterKind;
    includeSkipped = includeSkipped || false;
    const params = {
      limit: 366,
      raster_kind: kind,
      include_skipped: includeSkipped,
    };
    const cacheKey = parcelId + kind + includeSkipped;
    // Get the raster list from the cache if available otherwise get them
    // from the API.
    let parcelRasters =
      !noCache && context.getters.parcelRasterCache.getItem(cacheKey);
    if (!parcelRasters) {
      parcelRasters = (
        await apiCall<Paginated<Raster>>(
          "GET",
          `/farm/${farmId}/season/${seasonId}/parcel/${parcelId}/raster/`,
          params
        )
      ).results;
      context.commit("addToParcelRasterCache", {
        id: cacheKey,
        rasters: parcelRasters,
      });
    }
    // Return a copy so mutations don't affect the state
    return [...parcelRasters];
  },
};

export default {
  strict: true,
  state: initialState(),
  getters,
  mutations,
  actions,
} as Module<RastersState, RastersGetters, RastersActions>;
