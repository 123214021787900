import { SupportedCountries } from "@/lib/supported-countries";
import { Crop } from "@/lib/types/Crop";
import { Locality } from "@/lib/types/Locality";
import { Parcel } from "@/lib/types/Parcel";
import { ActionMap, createMutations, GetterMap, GetterTree, Module } from "..";

export type MapDrawStep = 'parcel' | 'crop';

export interface MapDrawState {
  selectedLocality: Locality;
  parcelIndex: Record<Parcel['id'], Parcel>;
  isSelecting: boolean;
  overlappedParcels: Record<Parcel['id'], Parcel['id'][]>;
  selectedCrop: Crop;
  mapDrawStep: MapDrawStep;
  selectedCountry: SupportedCountries;
  editingLocked: boolean;
}

function initialState(): MapDrawState {
  return {
    selectedLocality: null,
    parcelIndex: {},
    isSelecting: false,
    overlappedParcels: {},
    selectedCrop: null,
    mapDrawStep: 'parcel',
    selectedCountry: SupportedCountries.RO,
    editingLocked: false,
  }
}

const mutations = createMutations({
  clearMapDraw(state: MapDrawState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setMapDrawSelectedLocality(state, locality: Locality) {
    state.selectedLocality = locality;
  },
  setMapDrawParcelIndex(state, parcelIndex: Record<Parcel['id'], Parcel>) {
    state.parcelIndex = parcelIndex;
  },
  updateMapDrawParcel(state, parcel: Parcel) {
    state.parcelIndex[parcel.id] = parcel;
  },
  removeMapDrawParcel(state, parcelId: string) {
    const parcelIndex = { ...state.parcelIndex };
    delete parcelIndex[parcelId];
    state.parcelIndex = parcelIndex;
  },
  setMapDrawIsSelecting(state, value: boolean) {
    state.isSelecting = value;
    if(!value) {
      Object.keys(state.parcelIndex).forEach(id => {
        state.parcelIndex[id] = { ...state.parcelIndex[id], selected: false }
      });
    }
  },
  setMapDrawOverlappedParcels(state, overlappedParcels: MapDrawState['overlappedParcels']) {
    state.overlappedParcels = overlappedParcels;
  },
  setMapDrawSelectedCrop(state, crop: Crop) {
    state.selectedCrop = crop;
  },
  setMapDrawStep(state, step: MapDrawStep) {
    state.mapDrawStep = step;
  },
  setMapDrawSelectedCountry(state, country: SupportedCountries) {
    state.selectedCountry = country;
  },
  setMapDrawEditingLocked(state, value: boolean) {
    state.editingLocked = value;
  }
});

export type MapDrawMutations = typeof mutations;

export interface MapDrawGetters extends GetterMap {
  mapDrawSelectedLocality: Locality;
  mapDrawParcelIndex: Record<Parcel['id'], Parcel>;
  mapDrawParcels: Parcel[];
  mapDrawIsSelecting: boolean;
  mapDrawSelectedParcels: Parcel[];
  mapDrawOverlappedParcels: MapDrawState['overlappedParcels'];
  mapDrawSelectedCrop: Crop;
  mapDrawStep: MapDrawStep;
  mapDrawSelectedCountry: SupportedCountries;
  mapDrawEditingLocked: boolean;
}

const getters: GetterTree<MapDrawState, MapDrawGetters> = {
  mapDrawSelectedLocality(state) {
    return state.selectedLocality;
  },
  mapDrawParcelIndex(state) {
    return state.parcelIndex;
  },
  mapDrawParcels(state) {
    return Object.values(state.parcelIndex);
  },
  mapDrawIsSelecting(state) {
    return state.isSelecting;
  },
  mapDrawSelectedParcels(state, getters) {
    return getters.mapDrawParcels.filter(parcel => parcel.selected);
  },
  mapDrawOverlappedParcels(state) {
    return state.overlappedParcels;
  },
  mapDrawSelectedCrop(state) {
    return state.selectedCrop;
  },
  mapDrawStep(state) {
    return state.mapDrawStep;
  },
  mapDrawSelectedCountry(state) {
    return state.selectedCountry;
  },
  mapDrawEditingLocked(state) {
    return state.editingLocked;
  }
}

export interface MapDrawActions extends ActionMap {

}

export default {
  strict: true,
  state: initialState(),
  mutations,
  getters,
} as Module<MapDrawState, MapDrawGetters, MapDrawActions>;
