import debounce from "debounce";
import Vue from "vue";
import { ActionMap, ActionTree, createMutations, GetterMap, GetterTree, Module } from "..";

const STALE_BUSY = 10000;

const startProgress = debounce((vm: Vue) => {
  if (!vm.$store.getters.progressLoading) {
    vm.$store.commit("setProgressLoading", true);
    vm.$Progress.start();
  }
}, 400);

const finishProgress = debounce((vm: Vue) => {
  // Clear any leftover debounced tasks, if the busy queue
  // was cleared before the progress bar was shown.
  startProgress.clear();
  // Delayed check of the busy queue to prevent flickering to the progress bar.
  if (vm.$store.getters.busyCount === 0) {
    vm.$Progress.finish();
    vm.$store.commit("setProgressLoading", false);
  }
}, 200);

export interface InternalState {
  _busy: number[];
  _progressLoading: boolean;
  isMobile: boolean;
}

function initialState(): InternalState {
  return {
    _busy: [],
    _progressLoading: false,
    isMobile: false,
  };
}

const mutations = createMutations({
  clearInternal(state: InternalState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  /**
   * Add a busy indicator to the queue, to mark that a process has started
   */
  addBusy(state) {
    state._busy.push(Date.now());
  },
  /**
   * Remove a busy indicator from the queue, we don't need to remove any
   * specific ones. As we only need to know if the queue is empty.
   */
  removeBusy(state) {
    // Remove any stale busy indicators
    state._busy = state._busy.filter(
      (value) => Date.now() - value <= STALE_BUSY
    );
    // Pop the oldest one.
    state._busy.shift();
  },
  setProgressLoading(state, status) {
    state._progressLoading = status;
  },
  setIsMobile(state, isMobile: boolean) {
    state.isMobile = isMobile;
  }
});

export type InternalMutations = typeof mutations;

export interface InternalGetters extends GetterMap {
  busyCount: number;
  progressLoading: boolean;
  isMobile: boolean;
}

const getters: GetterTree<InternalState, InternalGetters> = {
  /**
  * Get the number of busy tasks ATM, ignoring stale tasks.
  */
  busyCount(state) {
    const now = Date.now();
    return state._busy.reduce((accumulator, currentValue) => {
      if (now - currentValue <= STALE_BUSY) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);
  },
  progressLoading(state) {
    return state._progressLoading;
  },
  isMobile(state) {
    return state.isMobile;
  }
}

export interface InternalActions extends ActionMap {
  startProgress: (vm: Vue) => Promise<void>;
  finishProgress: (vm: Vue) => Promise<void>;
}

const actions: ActionTree<InternalState, InternalGetters, InternalActions> = {
  /**
  * Action indicating that a task has started progress. Add to the
  * busy counter and starts the progress bar, if it isn't started already.
  */
  async startProgress(context, vm) {
    if (context.getters.busyCount === 0) {
      // Debounce-start immediately if the busy queue is empty ATM.
      startProgress(vm);
    }
    context.commit("addBusy");
  },
  /**
   * Action indicating that a task has finished it's progress. If all tasks have
   * finished the progress, then the progress bar is stopped.
   */
   async finishProgress(context, vm) {
    if (context.getters.busyCount === 0) {
      return;
    }
    context.commit("removeBusy");
    finishProgress(vm);
  },
}

export default {
  strict: true,
  state: initialState(),
  getters,
  mutations,
  actions,
} as Module<InternalState, InternalGetters, InternalActions>;
