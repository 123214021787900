import {
  dateEqual,
  formatDate,
  parseDate,
  seasonForDate,
} from "../../lib/util";
import { apiCall } from "../../lib/api";
import { ActionMap, ActionTree, createMutations, GetterMap, GetterTree, Module } from "..";
import { Season } from "@/lib/types/Season";

export interface DatesState {
  selectedDate: Date;
  availableDates: Date[];
  totalDates: number;
}

function initialState() {
  return { selectedDate: null, availableDates: [], totalDates: -1 };
}

const mutations = createMutations({
  clearDates(state: DatesState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setAvailableDates(state, dates) {
    state.availableDates = dates;
    state.totalDates = dates.length;
  },
  setSelectedDate(state, date) {
    state.selectedDate = date;
  },
});

export type DatesMutations = typeof mutations;

export interface DatesGetters extends GetterMap {
  availableDates: Date[];
  totalDates: number;
  selectedDate: Date;
  selectedDateString: string;
  selectedYear: number;
  latestDate: Date | null;
  latestSeason: Season;
  isLatestDate: boolean;
}

const getters: GetterTree<DatesState, DatesGetters> = {
  availableDates(state) {
    return state.availableDates;
  },
  totalDates(state) {
    return state.totalDates;
  },
  selectedDate(state) {
    return state.selectedDate;
  },
  selectedDateString(state) {
    return formatDate(state.selectedDate);
  },
  selectedYear(state) {
    return state.selectedDate && state.selectedDate.getFullYear();
  },
  latestDate(state) {
    return state.availableDates ? state.availableDates[0] : null;
  },
  latestSeason(state, getters, rootState, rootGetters) {
    return seasonForDate(rootGetters.seasons, getters.latestDate);
  },
  isLatestDate(state) {
    if (!state.availableDates) return false;
    return dateEqual(state.availableDates[0], state.selectedDate);
  },
}

export interface DatesActions extends ActionMap {
  getAvailableDates: () => Promise<void>;
}

const actions: ActionTree<DatesState, DatesGetters, DatesActions> = {
  /**
  * Get available sensing date for the current farm from the API and store the results.
  */
  async getAvailableDates(context) {
    if (!context.rootGetters.currentFarmId) return;
    const response = await apiCall<{ sensing_dates: string[] }>(
      "GET",
      `/farm/${context.rootGetters.currentFarmId}/sensing_dates/`
    );
    context.commit(
      "setAvailableDates",
      response.sensing_dates.map((date) => parseDate(date))
    );
  }
}

export default {
  strict: true,
  state: initialState(),
  mutations,
  getters,
  actions,
} as Module<DatesState, DatesGetters, DatesActions>;
