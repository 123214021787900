import _Vue from 'vue';
import _Component, { mixins as _mixins } from 'vue-class-component';
import { Store } from './custom';
import AnalyticsMixin from './lib/mixins/analytics';
import ClipboardMixin from './lib/mixins/clipboard';
import FormsMixin from './lib/mixins/forms';
import GoogleMapsMixin from './lib/mixins/googleMaps';
import NotificationMixin from './lib/mixins/notifications';
import UtilsMixin from './lib/mixins/utils';
import RoutesMixin from './lib/mixins/routes';

export const Component = _Component;

export const mixins = _mixins;

@Component
export class Vue extends _Vue {
}

@Component
export class MixedVue extends mixins(UtilsMixin, FormsMixin, ClipboardMixin, AnalyticsMixin, NotificationMixin, RoutesMixin) {
}
