import { Parcel } from "@/lib/types/Parcel";
import { Raster } from "@/lib/types/Raster";
import { ActionMap, createMutations, GetterMap, GetterTree, Module } from "..";

export enum MapOverlayMode {
  None = "none",
  Rasters = "rasters",
  Crops = "_CR",
}

export interface MapState {
  overlappedParcel: Parcel | null;
  highlightedRaster: Raster | null;
  overlayMode: MapOverlayMode;
  showPointsOfInterest: boolean;
}

const initialState: () => MapState = () => ({
  overlappedParcel: null,
  highlightedRaster: null,
  overlayMode: MapOverlayMode.Rasters,
  showPointsOfInterest: true,
});

const mutations = createMutations({
  clearMap(state: MapState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  setOverlappedParcel(state, parcel: Parcel | null) {
    state.overlappedParcel = parcel;
  },
  setOverlayMode(state, mode: MapOverlayMode) {
    state.overlayMode = mode;
  },
  setHighlightedRaster(state, raster: Raster | null) {
    state.highlightedRaster = raster;
  }
});

export type MapMutations = typeof mutations;

export interface MapGetters extends GetterMap {
  overlappedParcel: MapState["overlappedParcel"];
  highlightedRaster: MapState["highlightedRaster"];
  overlayMode: MapState["overlayMode"];
}

const getters: GetterTree<MapState, MapGetters> = {
  overlappedParcel(state) {
    return state.overlappedParcel;
  },
  highlightedRaster(state) {
    return state.highlightedRaster;
  },
  overlayMode(state) {
    return state.overlayMode;
  },
}

export interface MapActions extends ActionMap {

}

export default {
  strict: true,
  state: initialState(),
  mutations,
  getters,
} as Module<MapState, MapGetters, MapActions>;
