import { AgriculturalWork } from "@/lib/types/AgriculturalWork";
import { createMutations, ActionMap, GetterMap, GetterTree, ActionTree } from "..";
import { apiCall } from "@/lib/api";

export interface AgriculturalWorkState {
  agriculturalWorks: AgriculturalWork[];
}

const initialState: AgriculturalWorkState = {
  agriculturalWorks: [],
};

const mutations = createMutations({
  setAgriculturalWorks(state: AgriculturalWorkState, payload: AgriculturalWork[]) {
    state.agriculturalWorks = payload;
  },
  clearAgriculturalWorks(state: AgriculturalWorkState) {
    state.agriculturalWorks = [];
  },
});

const actions: ActionTree<AgriculturalWorkState, AgriculturalWorksGetters, AgriculturalWorksActions> = {
  async getAgriculturalWorks(context) {
    const response = await apiCall<{ results: AgriculturalWork[] }>("GET", "/agricultural-works");
    context.commit("setAgriculturalWorks", response.results);
  },
};

const getters: GetterTree<AgriculturalWorkState, AgriculturalWorksGetters> = {
  agriculturalWorks(state) {
    return state.agriculturalWorks;
  },
};

export interface AgriculturalWorksActions extends ActionMap {
  getAgriculturalWorks: () => Promise<void>;
}
export interface AgriculturalWorksGetters extends GetterMap {
  agriculturalWorks: AgriculturalWork[];
}
export type AgriculturalWorksMutations = typeof mutations;

export default {
  state: initialState,
  mutations,
  actions,
  getters,
};
