import { logError } from "../util";

import Vue from 'vue';
import Component from 'vue-class-component';
import { APIErrorReason } from "../api";

@Component
export default class FormsMixin extends Vue {
  errors!: Record<string, string>;

  clearErrors() {
    Object.keys(this.errors).forEach((key) => {
      this.errors[key] = "";
    });
  }
  setErrors(_reason: any) {
    const reason = _reason as APIErrorReason;
    if (reason.details) {
      if (typeof reason.details === "string") {
        this.errors.error = reason.details;
      } else {
        Object.entries(reason.details).forEach((entry) => {
          this.errors[entry[0]] = (entry[1] as string[]).join(" ");
        });
      }
    } else if (reason.detail || reason.error) {
      this.errors.error = reason.detail || reason.error;
    } else {
      this.errors.error = this.$gettext("Unknown error.");
      logError(reason);
    }
  }
};
