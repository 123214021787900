import { ciIncludes, unaccent } from "@/lib/util";
import { RootGetters } from "@/store";
import { Parcel } from "./types/Parcel";
import { getCropIntervalCrop } from "./util";

function cropFilterMatch(getters: RootGetters, parcel: Parcel) {
  if (!getters.selectedCropId) {
    return true;
  }
  const cropId = getCropIntervalCrop(
    parcel.crop_intervals,
    getters.selectedDate,
    getters.currentSeason
  );
  if (!cropId) {
    return getters.selectedCropId === "null";
  }
  return getters.allCrops[getters.selectedCropId].cropIds.has(cropId);
}
function localityFilterMatch(getters: RootGetters, parcel: Parcel) {
  if (getters.selectedLocalityIdsSet.size === 0) {
    return true;
  }
  return getters.selectedLocalityIdsSet.has(parcel.localityId);
}
function nameFilterMatch(getters, parcel) {
  if (!getters.parcelSearchText) return true;
  return ciIncludes(
    unaccent(parcel.locality || "") + " " + unaccent(parcel.reference || ""),
    getters.unaccentSearchText
  );
}
export function shouldShowParcel(getters: RootGetters, parcel: Parcel) {
  return (
    cropFilterMatch(getters, parcel) &&
    localityFilterMatch(getters, parcel) &&
    nameFilterMatch(getters, parcel)
  );
}
